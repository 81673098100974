import { Menu, MenuButton, MenuList, MenuItem, Link, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const UserMenu = ({ userInfo, logoutHandler, handleLinkClick }) => {
  
  const linkColor = useColorModeValue('black', 'white');
  const menuListBg = useColorModeValue('white', 'gray.800');
  const menuItemColor = useColorModeValue('black', 'white');
  const menuItemBorder = useColorModeValue('black', 'white');
  const menuItemHoverBg = useColorModeValue('black', 'white');
  const menuItemHoverColor = useColorModeValue('white', 'black');

  return (
    <Menu>
      <MenuButton
        as={Link}
        color={linkColor}
        variant='link'
        fontWeight='md'
        fontSize='sm'
        ml='0'
        letterSpacing='1.3px'
        textTransform='uppercase'
        gap='1'
        mt='-0.2'
        px='0'
        py='0'
        borderRadius='none'
        _hover={{
          textDecoration: 'underline',
          color: linkColor,
        }}
        _focus={{
          boxShadow: 'none',
          outline: 'none', 
        }}
      >
        {userInfo.name}
      </MenuButton>
      <MenuList
        border='none'
        boxShadow='md'
        mt='2'
        ml='1'
        bg={menuListBg}
        p='0'
      >
        <MenuItem
          as={RouterLink}
          to='/profile'
          onClick={handleLinkClick}
          color={menuItemColor}
          borderRadius='none'
          border={`1px solid ${menuItemBorder}`}
          _hover={{
            bgColor: menuItemHoverBg,
            color: menuItemHoverColor,
          }}
          transition='background-color 0.3s, color 0.3s'
          fontWeight='normal'
          fontSize='md'
          letterSpacing='wide'
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={logoutHandler}
          color={menuItemColor}
          borderRadius='none'
          border={`1px solid ${menuItemBorder}`}
          _hover={{
            bgColor: menuItemHoverBg,
            color: menuItemHoverColor,
          }}
          transition='background-color 0.3s, color 0.3s'
          fontWeight='normal'
          fontSize='md'
          letterSpacing='wide'
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
