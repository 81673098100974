import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Flex, Text, SimpleGrid } from '@chakra-ui/react';
import ProductCard from '../components/ProductCard'; 

const SearchScreen = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || ''; 
  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  useEffect(() => {
    document.title = `Search Results for "${query}" | REGENT NX CHAIRS`;
  }, [query]);

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Flex direction='column' p='4'>
      <Text fontSize='lg' mb='4' mt={{base:'78', md:'120', lg:'150px' }} fontWeight='500'>
        Search Results for "{query}"
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing='4'>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))
        ) : (
          <Text>No results found</Text>
        )}
      </SimpleGrid>
    </Flex>
  );
};

export default SearchScreen;
