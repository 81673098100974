import {
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Text,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Divider,
  useColorMode,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink,useParams } from 'react-router-dom';
import {
  createProductReview,
  listProductDetails,
  listProductsByCategory
} from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Rating from '../components/Rating';
import { PRODUCT_REVIEW_CREATE_RESET,} from '../constants/productConstants';
 import RelatedProducts from '../components/RelatedProducts';
 import GetPriceButton from '../components/GetPriceButton';

const ProductScreen = () => {
  const { colorMode } = useColorMode(); 
  const { id } = useParams();
  const dispatch = useDispatch();
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState('');
  const [selectedImage, setSelectedImage] = useState(0);
  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [reloadKey, setReloadKey] = useState(0); 

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, product = {}, error } = productDetails;


  const relatedProductsList = useSelector((state) => state.productList); 
  const { products: relatedProducts, loading: relatedLoading, error: relatedError } = relatedProductsList;
  

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      alert('Review submitted');
      setRating(1);
      setComment('');
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }
    dispatch(listProductDetails(id));
  }, [id, dispatch, successProductReview]);
    
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setReloadKey(prev => prev + 1); 
      }, 500); 

      return () => clearTimeout(timer); 
    }
  }, [error]);

  useEffect(() => {
    dispatch(listProductDetails(id)); 
  }, [dispatch, id, reloadKey]); 

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProductReview(id, { rating, comment }));
  };

  useEffect(() => {
    dispatch(listProductDetails(id)); 
  }, [dispatch, id]);

  useEffect(() => {
    if (product && product.category) {
      dispatch(listProductsByCategory(product.category));
    }
  }, [dispatch, product]);

  useEffect(() => {
    document.title = `${product.name} | REGENT NX CHAIRS`;
  }, [product.name]);

  const imageUrls = [
    product.image1,
    product.image2,
    product.image3,
    product.image4,
  ].filter((url) => url && url.trim() !== '/images/sample.jpg');   
  
  
  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleTouchStart = (e) => {
    
    if (e.touches && e.touches.length > 0) {
      setStartX(e.touches[0].clientX);
    }
  };

  const handleTouchMove = (e) => {
  
  if (e.touches && e.touches.length > 0) {
    setEndX(e.touches[0].clientX);
  }
};

  const handleTouchEnd = () => {
    if (startX === null || endX === null) return;

    const deltaX = startX - endX;

    if (deltaX > 50) {
      
      setSelectedImage((prev) => Math.min(prev + 1, imageUrls.length - 1));
    } else if (deltaX < -50) {
      
      setSelectedImage((prev) => Math.max(prev - 1, 0));
    }

    setStartX(null);
    setEndX(null);
    setIsDragging(false);
  };

  const bgColor = colorMode === 'dark' ? 'black' : 'white';
  const breadcrumbColor = colorMode === 'dark' ? 'gray.100' : 'gray.700';

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <>
          <Flex
            direction={{ base: 'column', md: 'column', lg:'row' }}
            mb="10"
            mt={{ base: '100', md: '130', lg: '150' }}
            align="center"
            justify="center"
          >
            {/* Main Image in the Center */}
            <Box flex="1" textAlign="center">
    <Image
      src={imageUrls[selectedImage]}
      alt={`Product Image ${selectedImage + 1}`}
      borderRadius="none"
      boxSize={{ base: '400px', md: '450px', lg: '450px' }}
      objectFit="contain"
      bgColor='white'
      mb="4"
      mt={{ base: '0', md: '0', lg: '3' }}
      ml={{ base: '0', md: '-150', lg: '250' }}
      transition="transform 0.3s ease-in-out"
      transform={`translateX(${isDragging ? '0' : '0'})`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleTouchStart}
      onMouseMove={(e) => isDragging && handleTouchMove(e)}
      onMouseUp={handleTouchEnd}
      _hover={{
        transform: 'scale(1.02)', 
        boxShadow: '2xl', 
        zIndex: "1",
      }}
      boxShadow='md'
    />
 <SimpleGrid columns={Math.min(imageUrls.length, 4)} spacing="0" align="center" justify="center">     
   {imageUrls.map((image, index) => (
        <Image
          key={index}
          src={image}
          alt={`Product Image ${index + 1}`}
          borderRadius="none"
          boxSize={{ base: '90px', md: '90px', lg: '100px' }}
          objectFit="contain"
          bgColor='white'
          ml={{ base: '0', md: '-150', lg: '250' }}
          cursor="pointer"
          borderBottom="1px solid" 
          borderRight="1px solid" 
          borderColor="gray.200" 
          boxShadow='sm'
          onClick={() => handleImageClick(index)}
          transition="transform 0.3s ease-in-out"
          _hover={{
            transform: 'scale(1.05)', 
            boxShadow: 'lg', 
            zIndex: "1"
          }}
        />
      ))}
    </SimpleGrid>
  </Box>

           {/* Right Section: Breadcrumbs, Name, Features */}
<Box 
  flex="2" 
  fontWeight="200" 
  fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}  
  textAlign="left"
  ml={{ base: '0', md: '500', lg: '300' }} 
  mt={{base:'0', md:'0', lg:'40'}}
>
  <Breadcrumb 
    color={breadcrumbColor}
    letterSpacing="wide"  
    mt={{ base: '5', md: '-550', lg: '-42%' }}
    ml={{ base: '0', md: '0', lg: '0' }} 
  >
    <BreadcrumbItem>
      <BreadcrumbLink zIndex={1} as={RouterLink} to="/">
        Home
      </BreadcrumbLink>
    </BreadcrumbItem>

    <BreadcrumbItem>
      <BreadcrumbLink zIndex={1} as={RouterLink} to={`/${product.type}`}>
        {product.type}
      </BreadcrumbLink>
    </BreadcrumbItem>

    <BreadcrumbItem>
      <BreadcrumbLink as={RouterLink} to={`/products/${product.category}`}>
        {product.category}
      </BreadcrumbLink>
    </BreadcrumbItem>

    <BreadcrumbItem isCurrentPage>
      <BreadcrumbLink>{product.name}</BreadcrumbLink>
    </BreadcrumbItem>
  </Breadcrumb>

  <Heading 
    as="h2" 
    fontWeight="semibold" 
    fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} 
    mt={{ base: '5', md: '5', lg: '5' }}
    ml={{ base: '0', md: '0', lg: '0' }} 
    letterSpacing="wide"
  >
    {product.name || 'Product Name'}
  </Heading>

  {/* Get Price Button */}
  <Box mt={{ base: '5', md: '5', lg: '-180' }} 
       ml={{ base: '0', md: '0', lg: '-290' }}  
  textAlign="left">
    <GetPriceButton product={product} />
  </Box>

  <Heading 
    as="h6" 
    letterSpacing="wide"  
    fontWeight="400" 
    fontSize={{ base: 'md', md: 'xl', lg: 'xl' }} 
    mt={{ base: '-7', md: '5', lg: '5' }}
    ml={{ base: '0', md: '0', lg: '0' }} 
  >
    Specifications
  </Heading>

  <Text 
    letterSpacing="wide"  
    fontWeight="md" 
    fontSize={{ base: 'xl', md: 'xl', lg: 'xl' }} 
    mt={{ base: '3', md: '4', lg: '5' }}
    ml={{ base: '0', md: '0', lg: '0' }} 
  >
    {product.features
      ? product.features.map((feature, index) => (
          <Text key={index} ml={{ base: '0', md: '0', lg: '0' }}>
            - {feature}
          </Text>
        ))
      : '- N/A'}
  </Text>
</Box>

          </Flex>
          <Box borderBottom="1px solid #e2e8f0" mb="10"></Box>

          {/* Tab System: Description, Additional Information, Reviews */}
          <Tabs isFitted variant="enclosed">
  <TabList mb="1em">
    <Tab
      _selected={{
        fontWeight: '700',
        letterSpacing: 'wide',
        borderBottomColor: colorMode === 'dark' ? 'whiteAlpha.900' : 'black',
        color: 'inherit', 
        bg: 'transparent', 
      }}
    >
      Description
    </Tab>
    <Tab
      _selected={{
        fontWeight: '700',
        letterSpacing: 'wide',
        borderBottomColor: colorMode === 'dark' ? 'whiteAlpha.900' : 'black',
        color: 'inherit', 
        bg: 'transparent', 
      }}
    >
      Additional Information
    </Tab>
    <Tab
      _selected={{
        fontWeight: '700',
        letterSpacing: 'wide',
        borderBottomColor: colorMode === 'dark' ? 'whiteAlpha.900' : 'black',
        color: 'inherit', 
        bg: 'transparent', 
      }}
    >
      Reviews
    </Tab>
  </TabList>
  
  <TabPanels>
    <TabPanel>
      <Box
        p={{ base: '4', md: '6', lg: '8' }} 
        maxWidth={{ base: '100%', md: '80%', lg: '70%' }} 
        mx="auto" 
      >
        <Text textAlign="center" letterSpacing='wide' fontSize={{ base: 'sm', md: 'md', lg: 'md' }}>
          {product.description || 'No description available'}
        </Text>
      </Box>
    </TabPanel>
    <TabPanel>
    <Box p={{ base: '2', md: '6', lg: '8' }} maxWidth={{ base: '100%', md: '80%', lg: '40%' }} mx="auto">
  {product.addInfo && product.addInfo.length > 0 ? (
    product.addInfo.map((info, index) => (
      <Box>
  {product.addInfo && product.addInfo.length > 0 ? (
    product.addInfo.map((info, index) => (
      <Box key={index} mb="3">
       <Flex direction="column" gap={2} mb={4}>
              {/* SKU */}
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  SKU
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.sku || 'N/A'}
                </Text>
              </Flex>
              <Divider />

              {/* Color Option */}
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Color Option
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.colorOption || 'N/A'}
                </Text>
              </Flex>
              <Divider />

              {/* Product Details */}
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Type of Product
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.typeOfProduct || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Material
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.material || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Style
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.style || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Model Number
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.modelNo || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Seat Color
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.seatColor || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Backrest Color
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.backrestColor || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Upholstery Material
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.upholsteryMaterial || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Wheel Material
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.wheelMaterial || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Country of Origin
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.countryOfOrigin || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Handle Material
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.handleMaterial || 'N/A'}
                </Text>
              </Flex>
              <Divider />

              {/* Dimensions */}
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Seat Height
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.seatHeight || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Minimum Seat Height
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.minSeatHeight || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Maximum Seat Height
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.maxSeatHeight || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Adjustable Height
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.adjustableHeight || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Backrest Height
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.backrestHeight || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Seat Depth
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.seatDepth || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Chair Width
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.chairWidth || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Chair Length
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.chairLength || 'N/A'}
                </Text>
              </Flex>
              <Divider />
              
              <Flex align="center" justify="space-between">
                <Text fontWeight="600" letterSpacing='wide' width="250px">
                  Chair Height
                </Text>
                <Text fontWeight="md" letterSpacing='wide' flex="1" textAlign="right">
                  {info.chairHeight || 'N/A'}
                </Text>
          </Flex>
          {index < product.addInfo.length - 1 && <Divider mt={4} mb={4} />}
        </Flex>
      </Box>
    ))
  ) : (
    <Text>No additional information available.</Text>
  )}
</Box>
    ))
  ) : (
    <Text mr={{ base: 'auto', md: 'auto', lg: '1' }} maxWidth={{ base: '100%', md: '80%', lg: '60%' }} mx="auto">No additional information available.</Text>
  )}
</Box>

              </TabPanel>
              <TabPanel>
                {product.reviews && product.reviews.length === 0 && (
                  <Message>No Reviews</Message>
                )}

                {product.reviews && product.reviews.length !== 0 && (
                  <Box
                  p="4"
                  backgroundColor= {bgColor}
                  borderWidth="2px"
                  borderColor={colorMode === 'dark' ? 'white' : 'black'}
                  borderRadius="none"
                  mb="1"
                  mt="5"
                  _hover={{
                    borderColor: colorMode === 'dark' ? 'gray.600' : 'black',
                    borderWidth: '3px',
                    borderStyle: 'solid',
                  }}
                  transition="border-color 0.3s, border-width 0.3s"
                >
                  {product.reviews.map((review) => (
                    <Flex
                      direction="column"
                      key={review._id}
                      mb="5"
                    >
                      <Flex justifyContent="space-between">
                      <Text fontSize="lg" color={colorMode === 'dark' ? 'gray.100' : 'black'}>
                          <strong>{review.name}</strong>
                        </Text>
                        <Rating value={review.rating} />
                      </Flex>
                      <Text mt="2" color={colorMode === 'dark' ? 'gray.200' : 'black'}>
                        {review.comment}</Text>
                    </Flex>
                  ))}
                </Box>
              )}
      
              {errorProductReview && (
                <Message type="error">{errorProductReview}</Message>
              )}
      
              {userInfo ? (
                <form onSubmit={submitHandler}>
                  <FormControl id="rating" letterSpacing="wide" mb="3">
        <FormLabel color={colorMode === 'dark' ? 'white' : 'black'}>Rating</FormLabel>
        <Select
          placeholder="Select Option"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          borderColor={colorMode === 'dark' ? 'gray.100' : 'gray.700'}
          borderRadius="none"
          _focus={{
            borderColor: colorMode === 'dark' ? 'gray.600' : 'black',
            boxShadow: 'none', // Remove the default box shadow
          }}
          _hover={{ borderColor: colorMode === 'dark' ? 'gray.600' : 'black' }}
          width={{ base: '100%', sm: '80%', md: '30%' }}
        >
          <option value="1">1 - Poor</option>
          <option value="2">2 - Okay</option>
          <option value="3">3 - Good</option>
          <option value="4">4 - Very Good</option>
          <option value="5">5 - Excellent</option>
        </Select>
      </FormControl>

      <FormControl id="comment" letterSpacing="wide" mb="3">
        <FormLabel color={colorMode === 'dark' ? 'white' : 'black'}>Comment</FormLabel>
        <Textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          borderColor={colorMode === 'dark' ? 'gray.100' : 'gray.700'}
          borderRadius="none"
          _focus={{
            borderColor: colorMode === 'dark' ? 'gray.600' : 'black',
            boxShadow: 'none', // Remove the default box shadow
          }}
          _hover={{ borderColor: colorMode === 'dark' ? 'gray.600' : 'black' }}
          width={{ base: '100%', sm: '80%', md: '40%' }}
          resize="vertical"
        />
      </FormControl>
      
                  <Button
                     color={colorMode === 'dark' ? 'white' : 'black'}
                    borderRadius='none'
                    type="submit"
                    border={`1px solid ${colorMode === 'dark' ? 'gray.100' : 'black'}`}
                    _hover={{ bgColor: colorMode === 'dark' ? 'white' : 'black', color:  colorMode === 'dark' ? 'black' : 'white' }}
                    transition="background-color 0.3s, color 0.3s"
                    _active={{
                      bg: colorMode === 'dark' ? 'white' : 'black',
                      color: 'white',
                      transform: 'scale(0.95)',
                    }}
                  >
                    Post Review
                  </Button>
                </form>
              ) : (
                <Message>Please login to write a review</Message>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
          {/* Related Products */}
          {relatedProducts && Array.isArray(relatedProducts) && relatedProducts.length > 0 ? (
        <RelatedProducts products={relatedProducts}
                          loading={relatedLoading}
                          error={relatedError} 
                          />
      ) : (
        <Box textAlign="center" mt="4">
          <Heading as="h4" size="md">No Related Products Found</Heading>
          </Box>
         ) }
        </>
        
      )}
    </>
  );
};

export default ProductScreen;
