import { Grid, Box, Heading, Text, Button, Menu, MenuButton, MenuList, MenuItem, Flex, useColorModeValue, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listProducts } from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ProductCard from '../components/ProductCard';

const ProductTypeScreen = () => {
	const { type: urlType } = useParams();
	const dispatch = useDispatch();
	const [type, setType] = useState(urlType || '');
	const [reloadKey, setReloadKey] = useState(0);

	const productList = useSelector((state) => state.productList);
	const { loading, products = [], error } = productList;

	const bg = useColorModeValue('white', 'black');
	const textColor = useColorModeValue('blackAlpha.900', 'whiteAlpha.800');
	const borderColor = useColorModeValue('black', 'white');
	const buttonHoverBg = useColorModeValue('black', 'white');
	const buttonHoverColor = useColorModeValue('white', 'black');

	useEffect(() => {
		dispatch(listProducts());
	}, [dispatch, reloadKey]);

	useEffect(() => {
		setType(urlType || '');
	}, [urlType]);

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				setReloadKey(prevKey => prevKey + 1); 
			}, 500); 

			return () => clearTimeout(timer); 
		}
	}, [error]);

	const handleTypeChange = (selectedType) => {
		setType(selectedType);
	};

	const filteredProducts = type
		? products
			.filter((product) => product.type === type)
			.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
		: [];
	
	const typeHeaders = {
        
		Chairs:'Chairs',
		Lounge:'Lounge',
		HighCounter:'High Counter Series'

	};	

	const typeDescriptions = {
		Chairs: 'Our collection of chairs encompasses a wide range of styles and functionalities, from executive high back chairs to ergonomic office solutions. Whether you need sophisticated seating for executives, comfortable options for staff, or specialized chairs for gaming and training, our diverse selection ensures that you will find the perfect chair for any environment. Each chair is designed with a focus on comfort, durability, and aesthetic appeal, making them ideal for both professional and personal use.',
		
		Lounge: 'Our lounge collection is all about relaxation and style, providing luxurious seating options for both office and home environments. Each piece in our lounge collection is designed for comfort and durability. These pieces are perfect for creating a cozy, inviting atmosphere in waiting areas, living rooms, or office lounges, blending high-end materials with superior craftsmanship to deliver a truly luxurious lounging experience.',
		
		HighCounter: 'Our High Counter Series offers a range of elevated seating solutions, perfect for environments that require a higher vantage point. From sleek and modern bar stools to sturdy counter-height chairs, this collection is designed to provide both comfort and style at an elevated level. Ideal for bar areas, kitchen islands, high-top tables, or elevated workspaces, each piece in this series is crafted with durability and ergonomic support in mind. Whether you are outfitting a trendy bar, a home kitchen, or a collaborative office space, our Higher Seating Series delivers the perfect blend of functionality and aesthetic appeal.'

	};
	
	const header = typeHeaders[type] || '';
	const description = typeDescriptions[type] || '';

	useEffect(() => {
        document.title = `${header} | REGENT NX CHAIRS`;
    }, [header]);

	return (
		<Box bg={bg} color={textColor} mt={{ base: '110', md: '100', lg: '130' }} px={{ base: '4', md: '8', lg: '12' }}>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<>
					{/* Container for Sort By Type Button and Heading */}
					<Box mb={8}>
						{/* Sort By Type Button */}
						<Flex justifyContent='right' mt={{ base: '0', md: '0', lg: '5' }}>
							<Menu>
								<MenuButton
									as={Button}
									color={textColor}
									borderRadius='none'
									border={`1px solid ${borderColor}`}
									_hover={{ bgColor: buttonHoverBg, color: buttonHoverColor }}
									transition='background-color 0.3s, color 0.3s'
									px={2}
									py={2}
									fontSize={{
										base: '14px', 
										sm: '14px',   
										md: '16px',   
										lg: '16px',   
									}}
								>
									Sort By Type
								</MenuButton>
								<MenuList>
									<MenuItem onClick={() => handleTypeChange('Chairs')}>Chairs</MenuItem>
									<MenuItem onClick={() => handleTypeChange('Lounge')}>Lounge</MenuItem>
									<MenuItem onClick={() => handleTypeChange('HighCounter')}>High Counter Series</MenuItem>

								</MenuList>
							</Menu>
						</Flex>

						{/* Heading and Description */}
						<Box textAlign='center' mt={4}>
							<Heading
								as='h2'
								size='lg'
								mb={4}
								fontWeight='500'
								letterSpacing='wide'
								fontSize={{
									base: '20px', 
									sm: '24px',   
									md: '28px',   
									lg: '32px',   
								}}
							>
								{header}
							</Heading>
							{description && (
								<Text
									fontSize={{
										base: '14px', 
										sm: '16px',   
										md: '18px',   
										lg: '18px',   
									}}
									color={textColor}
									letterSpacing='wide'
								>
									{description}
								</Text>
							)}
						</Box>
					</Box>

					{/* Products Grid */}
					<Grid
						templateColumns={{
							base: 'repeat(2, 1fr)', 
							sm: 'repeat(3, 1fr)',   
							lg: 'repeat(4, 1fr)'    
						}}
						gap={{ base: '0', sm: '0', md: '0', lg: '0' }} 
					>
						{filteredProducts.length === 0 ? (
							<Message>No products found for this type</Message>
						) : (
							filteredProducts.map((prod) => (
								<ProductCard key={prod._id} product={prod} />
							))
						)}
					</Grid>
				</>
			)}
		</Box>
	);
};

export default ProductTypeScreen;