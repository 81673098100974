import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Logo = () => {
  const textColor = useColorModeValue('black', 'white');

  return (
    <Flex
      align='center'
      justify='center'
      flex='1'
      flexDirection='column'
      px='4' 
      py='2' 
      borderRadius='md' 
    >
      <RouterLink to='/' aria-label='Home'>
        <Flex direction='column' align='center'>
          <Text
            fontSize={{ base: '3xl', md: '3xl', lg: '5xl', xl:'7xl' }} 
            fontWeight='bold'
            color={textColor} 
            letterSpacing='-0.01em' 
            textTransform='uppercase' 
            textAlign='center' 
            fontFamily='Antic Didone, sans-serif' 
            mb='1' 
            mr={{ base: '-5', md: '0', lg: '-7' }}
            mt={{ base: '-5', md: '-5', lg: '-7' }} 
          >
            REGENT NX
          </Text>
          <Text
            fontSize={{ base: 'md', md: 'md', lg: '2xl' }} 
            color={textColor} 
            fontWeight='normal'
            letterSpacing='0.1em' 
            textAlign='center' 
            fontFamily='Antic Didone, sans-serif' 
            mr={{ base: '-5', md: '0', lg: '-7' }}
            mt={{ base: '-4', md: '-3', lg: '-7' }} 
          >
            CHAIRS
          </Text>
        </Flex>
      </RouterLink>
    </Flex>
  );
};

export default Logo;
