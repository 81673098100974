import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Spacer,
	Select,
	Checkbox,
	List,
    ListItem,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { listProductDetails, updateProduct } from '../actions/productActions';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import axios from "axios";
import { DeleteIcon } from '@chakra-ui/icons';

const ProductEditScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id: productId } = useParams();

	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");
    const [image4, setImage4] = useState("");
    const [image5, setImage5] = useState("");
    const [image6, setImage6] = useState("");
    const [image7, setImage7] = useState("");
	const [image8, setImage8] = useState("");
	const [type, setType] = useState('');
	const [category, setCategory] = useState('');
	const [features, setFeatures] = useState([]);
	const [newFeature, setNewFeature] = useState('');
	const [popular, setPopular] = useState('No');
	const [description, setDescription] = useState('');
	const [addInfo, setAddInfo] = useState([{
		
		sku: '',
		colorOption: '',
		typeOfProduct: '',
		material: '',
		style: '',
		modelNo: '',
		seatColor: '',
		backrestColor: '',
		upholsteryMaterial: '',
		wheelMaterial: '',
		countryOfOrigin: '',
		handleMaterial: '',
		seatHeight: '',
		minSeatHeight: '',
		maxSeatHeight: '',
		adjustableHeight: '',
		backrestHeight: '',
		seatDepth: '',
		chairWidth: '',
		chairLength: '',
		chairHeight: ''
	  }]);

	const [countInStock, setCountInStock] = useState(0);
	const productDetails = useSelector((state) => state.productDetails);
	const { loading, error, product } = productDetails;

	console.log(product);

	const productUpdate = useSelector((state) => state.productUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = productUpdate;

	useEffect(() => {
		document.title = ' Create/Update Product | REGENT NX CHAIRS';
	  }, []);

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET });
			navigate(`/admin/productlist`);
		} else {
			if (!product.name || product._id !== productId) {
				dispatch(listProductDetails(productId));
			} else {
				setName(product.name);
				setPrice(product.price);
				setImage1(product.image1);
                setImage2(product.image2);
                setImage3(product.image3);
                setImage4(product.image4);
				setImage5(product.image5);
                setImage6(product.image6);
                setImage7(product.image7);
                setImage8(product.image8);
				setType(product.type);
				setCategory(product.category);
				setFeatures(Array.isArray(product.features) ? product.features : []);
				setAddInfo(product.addInfo);
				setCountInStock(product.countInStock);
				setDescription(product.description);
				setPopular(product.popular);
			}
		}
	}, [dispatch, navigate, productId, product, successUpdate]);

	const submitHandler = (e) => {
		e.preventDefault();

		dispatch(
			updateProduct({
				_id: productId,
				name,
				price,
				image1,
                image2,
                image3,
                image4,
				image5,
                image6,
                image7,
                image8,
				type,
				category,
				features,
				description,
				addInfo,
				countInStock,
				popular,
			})
		);
	};

	const uploadFileHandler = async (e, setImage) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		try {
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			const { data } = await axios.post("/api/uploads", formData, config);
			setImage(data);
		} catch (error) {
			console.log(error);
		}
	};

	
	const uploadFileHandler1 = (e) => uploadFileHandler(e, setImage1);
	const uploadFileHandler2 = (e) => uploadFileHandler(e, setImage2);
	const uploadFileHandler3 = (e) => uploadFileHandler(e, setImage3);
	const uploadFileHandler4 = (e) => uploadFileHandler(e, setImage4);
	
	  const addFeature = () => {
        if (newFeature.trim() && !features.includes(newFeature.trim())) {
            setFeatures((prevFeatures) => [...prevFeatures, newFeature.trim()]);
            setNewFeature('');
        }
    };

    const removeFeature = (featureToRemove) => {
        setFeatures((prevFeatures) => prevFeatures.filter((feature) => feature !== featureToRemove));
    };

	const bgColor = useColorModeValue('white', 'black');
	const inputPlaceholderColor = useColorModeValue('gray.500', 'gray.400');
	const inputBorderColor = useColorModeValue('gray.300', 'gray.600');
	const buttonBgColor = useColorModeValue('black', 'black');
	const buttonHoverBgColor = useColorModeValue('gray.800', 'gray.800');
	const textColor = useColorModeValue('black', 'black')

	return (
		<>

			<Flex  bg={bgColor} w='full' mt={{base:'10' ,md:'20'}} alignItems='center' justifyContent='center' py='5'>
				<FormContainer>
					<Heading color={textColor} as='h1' mb='8' fontSize='2xl'>
						Edit Product
					</Heading>

					{loadingUpdate && <Loader />}
					{errorUpdate && <Message type='error'>{errorUpdate}</Message>}

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<form onSubmit={submitHandler}>
							{/* NAME */}
							<FormControl id='name' isRequired>
								<FormLabel color={textColor}>Name</FormLabel>
								<Input
									type='text'
									placeholder='Enter name'
									value={name}
									onChange={(e) => setName(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
							</FormControl>
							<Spacer h='3' />

							{/* PRICE */}
							<FormControl id='price' >
								<FormLabel color={textColor}>Price</FormLabel>
								<Input
									type='number'
									placeholder='Enter price'
									value={price}
									onChange={(e) => setPrice(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
							</FormControl>
							<Spacer h='3' />

							{/* IMAGE */}
								<FormControl id="image" isRequired>
									<FormLabel color={textColor}>Image1</FormLabel>
									<Input
									type="text"
									placeholder="Enter image url"
									value={image1}
									onChange={(e) => setImage1(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
										/>
									<Input type="file" onChange={uploadFileHandler1} />
								</FormControl>
								<Spacer h="3" />

								<FormControl id="image">
									<FormLabel color={textColor}>Image2</FormLabel>
									<Input
									type="text"
									placeholder="Enter image url"
									value={image2}
									onChange={(e) => setImage2(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
									<Input type="file" onChange={uploadFileHandler2} />
								</FormControl>
								<Spacer h="3" />
								<FormControl id="image" >
									<FormLabel color={textColor}>Image3</FormLabel>
									<Input
									type="text"
									placeholder="Enter image url"
									value={image3}
									onChange={(e) => setImage3(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
									<Input type="file" onChange={uploadFileHandler3} />
								</FormControl>
								<Spacer h="3" />
								<FormControl id="image">
									<FormLabel color={textColor}>Image4</FormLabel>
									<Input
									type="text"
									placeholder="Enter image url"
									value={image4}
									onChange={(e) => setImage4(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
									<Input type="file" onChange={uploadFileHandler4} />
								</FormControl>
								<Spacer h="3" />
			  
                            {/* FEATURES */}
                            <FormControl id='features'>
                                <FormLabel color={textColor}>Features</FormLabel>
                                <Input
                                    type='text'
                                    placeholder='Enter feature'
                                    value={newFeature}
                                    onChange={(e) => setNewFeature(e.target.value)}
									color='black'
									
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
									/>
                                <Button onClick={addFeature} mt='2' fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
										letterSpacing="wide"
										borderRadius="none"
										width="150px"
										bg='black'
										color="white"
										_hover={{ bg: 'gray.800' }}
										_active={{
											bg: 'black',
											transform: 'scale(0.95)',
										}}
										>
													Add Feature
												</Button>
											</FormControl>
											<Spacer h='3' />

									<List spacing={3}>
										{features.map((feature, index) => (
											<ListItem color="black" key={index}>
												{feature}
                                        <IconButton
                                            aria-label='Remove feature'
                                            icon={<DeleteIcon />}
                                            onClick={() => removeFeature(feature)}
                                            ml='4'
											fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
											letterSpacing="wide"
											borderRadius="none"
											width="50px"
											bg="black"
											color="white"
											_hover={{ bg: 'gray.800' }}
											_active={{
											  bg: 'black',
											  color: 'white',
											  transform: 'scale(0.95)',
											}}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <Spacer h='3' />

							<FormControl  id="popular">
						<FormLabel color={textColor}>Popular</FormLabel>
						<Checkbox
							isChecked={popular === 'Yes'}
							onChange={(e) => setPopular(e.target.checked ? 'Yes' : 'No')}
							color={textColor}
							borderColor={inputBorderColor}
							_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
						>
							Mark as Popular
						</Checkbox>
						</FormControl>
						<Spacer h='3' />

							{/* DESCRIPTION */}
							<FormControl id='description'>
								<FormLabel color={textColor}>Description</FormLabel>
								<Input
									type='text'
									placeholder='Enter description'
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
							</FormControl>
							<Spacer h='3' />

							{/* TYpe */}
							<FormControl id='type' isRequired>
								<FormLabel color={textColor}>Type</FormLabel>
								<Select
									placeholder='Select Type'
									value={type}
									onChange={(e) => setType(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								>
									<option value='Chairs'>Chairs</option>
									<option value='Lounge'>Lounge</option>
									<option value='HighCounter'>High Counter Series</option>
									</Select>
							</FormControl>
							<Spacer h='3' />

							{/* CATEGORY */}
							<FormControl id='category' isRequired>
								<FormLabel color={textColor}>Category</FormLabel>
								<Select
									placeholder='Select Category'
									value={category}
									onChange={(e) => setCategory(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								  >
									<option value='Executive'>Executive</option>
									<option value='ErgonomicOffice'>Ergonomic Office</option>
									<option value='Gaming'>Gaming</option>
									<option value='Workstation'>Work Station</option>
									<option value='Conference'>Conference</option>
									<option value='Staff'>Staff</option>
									<option value='Visitors'>Visitors</option>
									<option value='TandemSeating'>Tandem Seating</option>
									<option value='Training'>Training</option>
									<option value='CafeteriaOutdoor'>Cafeteria/Outdoor</option>
									<option value='LoungeChairs'>Lounge Chairs</option>
									<option value='HighSeries'>High Counter Series</option>
									</Select>
							</FormControl>
							<Spacer h='3' />
							
							{/* ADDITIONAL INFORMATION */}
      
								{/* SKU and Color Options */}
								<FormControl id='sku'>
									<FormLabel color={textColor}>SKU</FormLabel>
									<Input
									type='text'
									placeholder='Enter SKU'
									value={addInfo.sku}
									onChange={(e) => setAddInfo({ ...addInfo, sku: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='colorOption'>
									<FormLabel color={textColor}>Color Option</FormLabel>
									<Input
									type='text'
									placeholder='Enter color'
									value={addInfo.colorOption}
									onChange={(e) => setAddInfo({ ...addInfo, colorOption: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								{/* Product Details */}
								<FormControl id='typeOfProduct'>
									<FormLabel color={textColor}>Type of Product</FormLabel>
									<Input
									type='text'
									placeholder='Enter type of product'
									value={addInfo.typeOfProduct}
									onChange={(e) => setAddInfo({ ...addInfo, typeOfProduct: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='material'>
									<FormLabel color={textColor}>Material</FormLabel>
									<Input
									type='text'
									placeholder='Enter material'
									value={addInfo.material}
									onChange={(e) => setAddInfo({ ...addInfo, material: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='style'>
									<FormLabel color={textColor}>Style</FormLabel>
									<Input
									type='text'
									placeholder='Enter style'
									value={addInfo.style}
									onChange={(e) => setAddInfo({ ...addInfo, style: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='modelNo'>
									<FormLabel color={textColor}>Model Number</FormLabel>
									<Input
									type='text'
									placeholder='Enter model number'
									value={addInfo.modelNo}
									onChange={(e) => setAddInfo({ ...addInfo, modelNo: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='seatColor'>
									<FormLabel color={textColor}>Seat Color</FormLabel>
									<Input
									type='text'
									placeholder='Enter seat color'
									value={addInfo.seatColor}
									onChange={(e) => setAddInfo({ ...addInfo, seatColor: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='backrestColor'>
									<FormLabel color={textColor}>Backrest Color</FormLabel>
									<Input
									type='text'
									placeholder='Enter backrest color'
									value={addInfo.backrestColor}
									onChange={(e) => setAddInfo({ ...addInfo, backrestColor: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='upholsteryMaterial'>
									<FormLabel color={textColor}>Upholstery Material</FormLabel>
									<Input
									type='text'
									placeholder='Enter upholstery material'
									value={addInfo.upholsteryMaterial}
									onChange={(e) => setAddInfo({ ...addInfo, upholsteryMaterial: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='wheelMaterial'>
									<FormLabel color={textColor}>Wheel Material</FormLabel>
									<Input
									type='text'
									placeholder='Enter wheel material'
									value={addInfo.wheelMaterial}
									onChange={(e) => setAddInfo({ ...addInfo, wheelMaterial: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='countryOfOrigin'>
									<FormLabel color={textColor}>Country of Origin</FormLabel>
									<Input
									type='text'
									placeholder='Enter country of origin'
									value={addInfo.countryOfOrigin}
									onChange={(e) => setAddInfo({ ...addInfo, countryOfOrigin: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='handleMaterial'>
									<FormLabel color={textColor}>Handle Material</FormLabel>
									<Input
									type='text'
									placeholder='Enter handle material'
									value={addInfo.handleMaterial}
									onChange={(e) => setAddInfo({ ...addInfo, handleMaterial: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								{/* Dimensions */}
								<FormControl id='seatHeight'>
									<FormLabel color={textColor}>Seat Height</FormLabel>
									<Input
									type='text'
									placeholder='Enter seat height'
									value={addInfo.seatHeight}
									onChange={(e) => setAddInfo({ ...addInfo, seatHeight: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='minSeatHeight'>
									<FormLabel color={textColor}>Minimum Seat Height</FormLabel>
									<Input
									type='text'
									placeholder='Enter minimum seat height'
									value={addInfo.minSeatHeight}
									onChange={(e) => setAddInfo({ ...addInfo, minSeatHeight: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='maxSeatHeight'>
									<FormLabel color={textColor}>Maximum Seat Height</FormLabel>
									<Input
									type='text'
									placeholder='Enter maximum seat height'
									value={addInfo.maxSeatHeight}
									onChange={(e) => setAddInfo({ ...addInfo, maxSeatHeight: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='adjustableHeight'>
									<FormLabel color={textColor}>Adjustable Height</FormLabel>
									<Input
									type='text'
									placeholder='Enter adjustable height'
									value={addInfo.adjustableHeight}
									onChange={(e) => setAddInfo({ ...addInfo, adjustableHeight: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='backrestHeight'>
									<FormLabel color={textColor}>Backrest Height</FormLabel>
									<Input
									type='text'
									placeholder='Enter backrest height'
									value={addInfo.backrestHeight}
									onChange={(e) => setAddInfo({ ...addInfo, backrestHeight: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='seatDepth'>
									<FormLabel color={textColor}>Seat Depth</FormLabel>
									<Input
									type='text'
									placeholder='Enter seat depth'
									value={addInfo.seatDepth}
									onChange={(e) => setAddInfo({ ...addInfo, seatDepth: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
															/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='chairWidth'>
									<FormLabel color={textColor}>Chair Width</FormLabel>
									<Input
									type='text'
									placeholder='Enter chair width'
									value={addInfo.chairWidth}
									onChange={(e) => setAddInfo({ ...addInfo, chairWidth: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='chairLength'>
									<FormLabel color={textColor}>Chair Length</FormLabel>
									<Input
									type='text'
									placeholder='Enter chair length'
									value={addInfo.chairLength}
									onChange={(e) => setAddInfo({ ...addInfo, chairLength: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
								</FormControl>
								<Spacer h='3' />

								<FormControl id='chairHeight'>
									<FormLabel color={textColor}>Chair Height</FormLabel>
									<Input
									type='text'
									placeholder='Enter chair height'
									value={addInfo.chairHeight}
									onChange={(e) => setAddInfo({ ...addInfo, chairHeight: e.target.value })}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
							</FormControl>
							<Spacer h='3' />
							

							{/* COUNT IN STOCK */}
							<FormControl id='countInStock'>
								<FormLabel color={textColor}>Count In Stock</FormLabel>
								<Input
									type='number'
									placeholder='Product in stock'
									value={countInStock}
									onChange={(e) => setCountInStock(e.target.value)}
									color={textColor}
									borderColor={inputBorderColor}
									_hover={{ borderColor: 'black' }}
									_focus={{ borderColor: 'black', boxShadow: 'none' }}
									_active={{ borderColor: 'black' }}
									_placeholder={{ color: inputPlaceholderColor }}
								/>
							</FormControl>
							<Spacer h='3' />

							<Button
								type='submit'
								isLoading={loading}
								mt='4'
								fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
								letterSpacing="wide"
								borderRadius="none"
								width="200px"
								bg={buttonBgColor}
								color="white"
								_hover={{ bg: buttonHoverBgColor }}
								_active={{
								  bg: buttonBgColor,
								  color: 'white',
								  transform: 'scale(0.95)',
								}}
								>
								Update
							</Button>
							<Button as={RouterLink} to='/admin/productlist'
							fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
							letterSpacing="wide"
							mt='4'
							ml='100'
							borderRadius="none"
							width="90px"
							bg={buttonBgColor}
							color="white"
							_hover={{ bg: buttonHoverBgColor }}
							_active={{
							  bg: buttonBgColor,
							  color: 'white',
							  transform: 'scale(0.95)',
							}}
							>
								Go Back
							</Button>
						</form>
					)}
				</FormContainer>
			</Flex>
		</>
	);
};

export default ProductEditScreen;
