import { Box, Grid, Heading, useColorMode } from '@chakra-ui/react';
import ProductCard from './ProductCard';
import Loader from './Loader'; 
import Message from './Message'; 


const shuffleArray = (array) => {
	const shuffledArray = [...array];
	for (let i = shuffledArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray;
};

const RelatedProducts = ({ products, loading, error }) => {
	const { colorMode } = useColorMode(); 
	
	const displayProducts = products.length > 0 ? shuffleArray(products).slice(0, 20) : [];

	return (
		<Box mt={{ base: '120', md: '0', lg: '180' }} px={{ base: '4', md: '8', lg: '12' }}>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<>
					{displayProducts.length > 0 ? (
						<Box mb={8}>
							<Heading
								as='h2'
								size='lg'
								mb={4}
								mt={{base:'-20', md:'5',lg:'-32'}}
								fontWeight='500'
								color={colorMode === 'dark' ? 'white' : 'black'}
								letterSpacing='wide'
								fontSize={{
									base: '20px', 
									sm: '24px',   
									md: '28px',   
									lg: '32px',   
								}}
							>
								Related Products
							</Heading>
							<Grid
								templateColumns={{
									base: 'repeat(2, 1fr)', 
									sm: 'repeat(3, 1fr)',   
									lg: 'repeat(4, 1fr)'    
								}}
								gap={{ base: '0', sm: '0', md: '0', lg: '0' }} 
							>
								{displayProducts.map((prod) => (
									<ProductCard key={prod._id} product={prod} />
								))}
							</Grid>
						</Box>
					) : (
						<Message>No related products found</Message>
					)}
				</>
			)}
		</Box>
	);
};

export default RelatedProducts;
