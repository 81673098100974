import { Link, Grid, GridItem, Box, Image, Text, Flex, useColorModeValue} from '@chakra-ui/react';
import image1 from '../Assets/images/office.jpg'; 
import image2 from '../Assets/images/lc.jpg';   
import image3 from '../Assets/images/barstool.jpg'; 

const Banner5 = () => {

  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('black', 'whiteAlpha.800');
  const hoverTextColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }} 
      width="100%"
      height={{ base: '100vh', md: '65vh',lg:'100vh' }} 
      overflow="hidden" 
      mt='-7'
    >
      <Flex
        direction="column"
        justify={{ base: 'center', md: 'flex-start' }} 
        align={{ base: 'center', md: 'flex-start' }}  
        width={{ base: '100%', md: '50%' }} 
        p={{ base: 4, md: 8 }} 
        backgroundColor={bgColor}
        textAlign={{ base: 'center', md: 'left' }} 
        position="relative"
        height={{ base: 'auto', md: '100%' }} 
      >
        <Text
          fontSize={{ base: 'xl', md: '2xl' }} 
          fontWeight="md"
          mb={4}
          color={textColor}
          letterSpacing="wide" 
          _hover={{
            textDecoration: 'underline', color: hoverTextColor,
          }}
        >
          Welcome to Regent NX
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'lg' }} 
          color={textColor}
          lineHeight="taller"
          mb={6}
          maxWidth={{ base: '90%', md: '80%' }} 
        >
          Explore our diverse range of products designed to meet your needs. Whether you're looking for something casual or professional, we have the right options for you. Click on the categories to see more details and make your choice.
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }} 
          color={textColor}
          letterSpacing="wide" 
          lineHeight="taller"
          maxWidth={{ base: '90%', md: '80%' }} 
        >
          Each category features a unique selection of products tailored to fit various styles and preferences. Discover the perfect items for your home or office and enjoy quality and style in every choice.
        </Text>
      </Flex>
      <Grid
        templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }} 
        templateRows={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }} 
        gap={0} 
        width={{ base: '100%', md: '50%' }} 
        height={{ base: 'auto', md: '100%' }} 
        mt={{ base: 4, md: 0 }} 
        p={{ base: 0, md: 0 }} 
      >
        <GridItem
          colSpan={{ base: 1, md: 1 }} 
          rowSpan={{ base: 1, md: 1 }} 
          position="relative"
          overflow="hidden"
          width="100%" 
          height={{ base: '200px', md: 'auto' }} 
          p={0} 
        >
          <Link href="/Chairs" width="100%" height="100%">
            <Box
              position="relative"
              width="100%"
              height="100%"
              paddingBottom="100%" 
              overflow="hidden"
              borderRadius="none"
              background="gray.200" 
              _hover={{ boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
            >
              <Image
                src={image1}
                alt="Chairs"
                objectFit="cover"
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                filter="brightness(100%)"
              />
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                background="rgba(0, 0, 0, 0.3)" 
                opacity="0"
                transition="opacity 0.3s ease-in-out"
                _groupHover={{ opacity: '1' }} 
              />
              <Text
                  fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
                  letterSpacing='wider' 
                 fontWeight="semibold"
                 color="white"
                 position="absolute"
                 bottom="10%"
                 left="5%"
                 p="2"
                 background="rgba(0, 0, 0, 0.4)"
                 borderRadius="md"
                 maxWidth="80%"
                 zIndex="1"
                 boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
                 textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
                transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
                _hover={{
                  transform: 'translateY(-15px)',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
                }}
              >
                CHAIRS
              </Text>
            </Box>
          </Link>
        </GridItem>

        <GridItem
          colSpan={{ base: 1, md: 1 }} 
          rowSpan={{ base: 1, md: 1 }} 
          position="relative"
          overflow="hidden"
          width="100%" 
          height={{ base: '200px', md: 'auto' }} 
          p={0} 
        >
          <Link href="/Lounge" width="100%" height="100%">
            <Box
              position="relative"
              width="100%"
              height="100%"
              paddingBottom="100%" 
              overflow="hidden"
              borderRadius="none"
              background="gray.200" 
              _hover={{ boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
            >
              <Image
                src={image2}
                alt="Lounge"
                objectFit="cover"
                position="absolute"
                top={{ base: '0', md: '0', lg:'0'}}
                left={{ base: '0', md: '0', lg:'0'}}
                width="100%"
                height="100%"
                filter="brightness(100%)"
              />
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                background="rgba(0, 0, 0, 0.3)" 
                opacity="0"
                transition="opacity 0.3s ease-in-out"
                _groupHover={{ opacity: '1' }} 
              />
              <Text
                  fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
                  letterSpacing='wider' 
                 fontWeight="semibold"
                 color="white"
                 position="absolute"
                 bottom="10%"
                 left={{ base: '55%', md: '10%', lg:'70%'}}
                 p="2"
                 background="rgba(0, 0, 0, 0.4)"
                 borderRadius="md"
                 maxWidth="80%"
                 zIndex="1"
                 boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
                 textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
                transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
                _hover={{
                  transform: 'translateY(-15px)',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
                }}
              >
                LOUNGES
              </Text>
            </Box>
          </Link>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, md: 2 }} 
          rowSpan={{ base: 1, md: 2 }} 
          position="relative"
          overflow="hidden"
          width="100%" 
          height={{ base: '200px', md: 'auto' }} 
          p={0} 
        >
          <Link href="/HighCounter" width="100%" height="100%">
            <Box
              position="relative"
              width="100%"
              height="100%"
              paddingBottom="100%" 
              overflow="hidden"
              borderRadius="none"
              background="gray.200" 
              _hover={{ boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
            >
              <Image
                src={image3}
                alt="High Counter"
                objectFit="cover"
                position="absolute"
                top={{base:'-135', md:'-88', lg:'-60'}}
                left="0"
                width="100%"
                height="100%"
                filter="brightness(100%)"
              />
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                background="rgba(0, 0, 0, 0.3)" 
                opacity="0"
                transition="opacity 0.3s ease-in-out"
                _groupHover={{ opacity: '1' }} 
              />
              <Text
                 fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
                 letterSpacing='wider' 
                 fontWeight="semibold"
                 color="white"
                 position="absolute"
                 bottom={{base:'233', md:'200', lg:'430'}}
                 left={{base:'1%', md:'25%', lg:'35%'}}
                 p="2"
                 background="rgba(0, 0, 0, 0.4)"
                 borderRadius="md"
                 maxWidth="80%"
                 zIndex="1"
                 boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
                 textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
                transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
                _hover={{
                  transform: 'translateY(-15px)',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
                }}
              >
                HIGH COUNTER SERIES
              </Text>
            </Box>
          </Link>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default Banner5;
