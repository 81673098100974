import { Link, Menu, MenuButton, MenuList, MenuItem, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const AdminMenu = ({ handleLinkClick }) => {

  const linkColor = useColorModeValue('black', 'white');
  const menuListBg = useColorModeValue('white', 'gray.800');
  const menuItemColor = useColorModeValue('black', 'white');
  const menuItemBorder = useColorModeValue('black', 'white');
  const menuItemHoverBg = useColorModeValue('black', 'white');
  const menuItemHoverColor = useColorModeValue('white', 'black');

  return (
    <Menu>
      <MenuButton
        as={Link}
        color={linkColor}
        variant='link'
        fontWeight='md'
        fontSize='sm'
        textTransform='uppercase'
        ml='4'
        letterSpacing='0.9px'
        mt='-0.2'
        px='0'
        py='0'
        gap='1'
        borderRadius='none'
        _hover={{
          textDecoration: 'underline',
          color: linkColor,
        }}
        _focus={{
          boxShadow: 'none',
          outline: 'none', 
        }}
      >
        Manage
      </MenuButton>
      <MenuList
        border='none'
        boxShadow='md'
        mt='2'
        ml='1'
        bg={menuListBg}
        p='0'
      >
        <MenuItem
          as={RouterLink}
          to='/admin/userlist'
          onClick={handleLinkClick}
          color={menuItemColor}
          borderRadius='none'
          border={`1px solid ${menuItemBorder}`}
          _hover={{
            bgColor: menuItemHoverBg,
            color: menuItemHoverColor,
            textDecoration: 'none',
          }}
          transition='background-color 0.3s, color 0.3s'
          fontWeight='normal'
          fontSize='md'
          letterSpacing='wide'
        >
          User List
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to='/admin/productlist'
          onClick={handleLinkClick}
          color={menuItemColor}
          borderRadius='none'
          border={`1px solid ${menuItemBorder}`}
          _hover={{
            bgColor: menuItemHoverBg,
            color: menuItemHoverColor,
            textDecoration: 'none',
          }}
          transition='background-color 0.3s, color 0.3s'
          fontWeight='normal'
          fontSize='md'
          letterSpacing='wide'
        >
          Product List
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to='/admin/orderlist'
          onClick={handleLinkClick}
          color={menuItemColor}
          borderRadius='none'
          border={`1px solid ${menuItemBorder}`}
          _hover={{
            bgColor: menuItemHoverBg,
            color: menuItemHoverColor,
            textDecoration: 'none',
          }}
          transition='background-color 0.3s, color 0.3s'
          fontWeight='normal'
          fontSize='md'
          letterSpacing='wide'
        >
          Order List
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default AdminMenu;
