import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

const Footer = () => {
  const footerBg = useColorModeValue('white', 'black'); // White in light mode, dark gray in dark mode
  const footerColor = useColorModeValue('black', 'whiteAlpha.800'); // Black in light mode, white in dark mode

  return (
    <Flex
      as='footer'
      direction='column'
      letterSpacing='wide'
      align='center'
      justify='center'
      py={{ base: '3', md: '3' }}
      width='100%'
      bg={footerBg} // Dynamic background color
      color={footerColor} // Dynamic text color
      position='relative'
      bottom='0'
    >
      <Text textAlign='center' fontSize={{ base: 'sm', md: 'md' }}>
        &copy; 2024 Regent NX Chairs. All Rights Reserved.
      </Text>
    </Flex>
  );
};

export default Footer;
