import { Link, Box, Image, Text, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import image4 from '../Assets/images/Mesh.jpg';
import image5 from '../Assets/images/staff.jpg';
import image2 from '../Assets/images/Gaming2.jpg'; 

const Banner3 = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + windowHeight > documentHeight - 200); 

    setScrollY(scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const imageSize = useBreakpointValue({ base: '45vh', md: '45vh', lg:'100vh'});

  const navigate = useNavigate(); 

  return (
    <SimpleGrid
      width="100%"
      height={{ base: 'auto', md: '60vh', lg:'100vh' }} 
      columns={{ base: 2, md: 3 , lg: 3 }} 
      spacing={0} 
      position="relative" 
    >
      {/* Image 1 */}
      <Box
        height={imageSize} 
        position="relative"
        overflow="hidden" 
        transition="transform 0.5s ease, box-shadow 0.5s ease" 
        transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1})`} 
        _hover={{
          transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1.05})`, 
          boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)', 
        }}
      >
        <Link onClick={() => navigate('products/ErgonomicOffice')} width="100%" height="100%">
          <Image
            src={image4}
            alt="Ergonomic Ofice Chairs"
            objectFit={{base:'cover',  lg:'fill'}}
            width="100%"
            height="100%"
            filter="brightness(100%)"
          />
        </Link>
        <Link onClick={() => navigate('products/ErgonomicOffice')} position="absolute" width="100%" height="100%" top="0" left="0" />
        <Text
          fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
          letterSpacing='wider'
          fontWeight="semibold"
          color="white"
          position="absolute"
          bottom={{base:'5%', md:'5%',lg:'10%'}}
          left="5%"
          p="2"
          background="rgba(0, 0, 0, 0.4)"
          borderRadius="md"
          maxWidth="80%"
          zIndex="1"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
          textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
         transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
         _hover={{
           transform: 'translateY(-15px)',
           textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
         }}
        >
          <Link onClick={() => navigate('products/ErgonomicOffice')} color="white" _hover={{ textDecoration: 'none' }}>
            ERGONOMIC OFFICE CHAIRS
          </Link>
        </Text>
      </Box>

      {/* Image 2 */}
      <Box
        height={imageSize} 
        position="relative"
        overflow="hidden" 
        transition="transform 0.5s ease, box-shadow 0.5s ease" 
        transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1})`} 
        _hover={{
          transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1.05})`, 
          boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)', 
        }}
      >
        <Link onClick={() => navigate('/products/Staff')} width="100%" height="100%">
          <Image
            src={image5}
            alt="Staff Chairs"
            objectFit="cover"
            width="100%"
            height="100%"
            filter="brightness(100%)"
          />
        </Link>
        <Link onClick={() => navigate('/products/Staff')} position="absolute" width="100%" height="100%" top="0" left="0" />
        <Text
          fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
          letterSpacing='wider'
          fontWeight="semibold"
          color="white"
          position="absolute"
          bottom={{base:'9%', md:'5%',lg:'10%'}}
          left="5%"
          p="2"
          background="rgba(0, 0, 0, 0.4)"
          borderRadius="md"
          maxWidth="80%"
          zIndex="1"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
          textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
         transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
         _hover={{
           transform: 'translateY(-15px)',
           textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
         }}
        >
          <Link onClick={() => navigate('/products/Staff')} color="white" _hover={{ textDecoration: 'none' }}>
            STAFF CHAIRS
          </Link>
        </Text>
      </Box>

      {/* Image 3 */}
      <Box
  height={imageSize}  
  width={["200%", "200%", "100%"]} 
  position="relative"
  overflow="hidden" 
  transition="transform 0.5s ease, box-shadow 0.5s ease" 
  transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1})`} 
  _hover={{
    transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1.05})`, 
    boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)', 
  }}
>
  <Link onClick={() => navigate('/products/Gaming')} width="100%" height="100%">
    <Image
      src={image2}
      alt="Gaming Chairs"
      objectFit="cover"
      width="100%"
      height="100%"
      filter="brightness(100%)"
    />
  </Link>
  <Link onClick={() => navigate('/products/Gaming')} position="absolute" width="100%" height="100%" top="0" left="0" />
  <Text
     fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
     letterSpacing='wider' 
     fontWeight="semibold"
     color="white"
     position="absolute"
     bottom={{base:'5%', md:'5%',lg:'10%'}}
     left="5%"
     p="2"
     background="rgba(0, 0, 0, 0.4)"
     borderRadius="md"
     maxWidth="80%"
     zIndex="1"
     boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
     textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
    transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
    _hover={{
      transform: 'translateY(-15px)',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
    }}
  >
    <Link onClick={() => navigate('/products/Gaming')} color="white" _hover={{ textDecoration: 'none' }}>
      GAMING CHAIRS
    </Link>
  </Text>
</Box>
    </SimpleGrid>
    
  );
};

export default Banner3;
