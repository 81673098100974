import React from 'react';
import { Button, useColorMode } from '@chakra-ui/react';

const GetPriceButton = ({ product }) => {
  const { colorMode } = useColorMode(); // Get the current color mode

  const handleClick = () => {
    let productImgUrl = `www.regentchair.com${product.image1}`;
    const message = `Hello, I am interested in this product:\n\n${product.name}\n${product.category}\n${product.type}\n\nFeatures:\n${product.features.join('\n')}\n\nView the product image here:\n${productImgUrl}\n\nPlease provide the price.`;
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = '+919833602186'; // Replace with your WhatsApp phone number
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
    
    
  };

  return (
    <Button
      bg={colorMode === 'dark' ? 'white' : 'black'} // Background color for dark and light modes
      color={colorMode === 'dark' ? 'black' : 'white'} // Text color for dark and light modes
      borderRadius="none"
      border={`1px solid ${colorMode === 'dark' ? 'gray.600' : 'black'}`} // Border color for dark and light modes
      px={2}
      py={2}
      fontSize={{
        base: '14px',
        sm: '14px',   
        md: '16px',   
        lg: '16px',  
      }}
      onClick={handleClick}
      mt={{ base: '-20', md: '0', lg: '200' }} 
      ml={{ base: '300', md: '0', lg: '300' }}
      _hover={{ bg: colorMode === 'dark' ? 'gray.900' : 'gray.200', color: colorMode === 'dark' ? 'white' : 'black' }}  
      _active={{
        bg: colorMode === 'dark' ? 'white' : 'black',
        color: colorMode === 'dark' ? 'white' : 'white',
        transform: 'scale(0.95)', 
      }}
      transition="transform 0.1s ease"
    >
      Get Price
    </Button>
  );
};

export default GetPriceButton;
