import React, { useEffect } from 'react';
import { Box, Container, Heading, Text, Stack, List, ListItem, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import aboutImage from '../Assets/images/about5.jpg'; 

const AboutScreen = () => {
  
  const headingSize = useBreakpointValue({ base: "md", md: "xl", lg: "xl" });
  const subheadingSize = useBreakpointValue({ base: "xl", md: "2xl", lg: "2xl" });
  const textSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });
  const isAbove700px = useBreakpointValue({ base: false, md: true }); 
  const textColor = useColorModeValue('black', 'whiteAlpha.800');

  useEffect(() => {
    document.title = 'About Us | REGENT NX CHAIRS';
  }, []);

  return (
    <Box>
      {/* Background Image Section  */}
      <Box 
        position="relative"
        backgroundImage={`url(${aboutImage})`} 
        backgroundSize={{base:'contain', md:'cover',  lg:'cover'}} 
        backgroundPosition="center"
        backgroundRepeat="no-repeat" 
        height={{ base: "50vh", md: "70vh", lg: "80vh" }} 
        width="100vw" 
        marginLeft="calc(-50vw + 50%)" 
        marginRight="calc(-50vw + 50%)" 
        marginTop={{ base: "-85px", md: "0", lg: "-5" }} 
        backgroundAttachment={isAbove700px ? "fixed" : "scroll"} 
        className={isAbove700px ? "parallax" : ""}
      >
        <Box 
          position="absolute" 
          top={0} 
          left={0} 
          right={0} 
          bottom={0} 
          zIndex={1}
        />
        <Box 
          position="relative" 
          zIndex={2}
          color="white"
          textAlign="center"
          px={4} 
          display="flex"
          flexDirection="column"
          justifyContent="center" 
          alignItems="center"
          height="100%" 
        >
          <Text 
          fontSize={headingSize} 
          fontWeight="md" 
          mb={2}
          color='white'
          background="rgba(0, 0, 0, 0.4)"
          borderRadius="lg"
          maxWidth="100%"
          zIndex="1"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
          textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
          transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
          _hover={{
           transform: 'translateY(-15px)',
           textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
         }}
          >
            — Experts for a quality output!
          </Text>
          <Heading 
          as="h2" 
          size={subheadingSize} 
          fontWeight="md"
          background="rgba(0, 0, 0, 0.4)"
          borderRadius="lg"
          maxWidth="100%"
          color='white'
          zIndex="1"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
          textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
         transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
         _hover={{
           transform: 'translateY(-15px)',
           textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
         }}
          >
            Best-in-class seating systems
          </Heading>
        </Box>
      </Box>
      
      {/* Content Section */}
      <Container maxW="container.xl" py={{ base: 6, md: 8, lg: 12 }}>
        <Stack spacing={{ base: 4, md: 6, lg: 8 }} textAlign="center">
          {/* About Us Section */}
          <Heading as="h1" size={{ base: "lg", md: "xl", lg: "xl" }} mt={{ base: '-20', md:'0' , lg: '0' }} fontWeight="md" mb={{ base: 4, md: 6, lg: 8 }} color={textColor}>
            About Us
          </Heading>
          <Text fontSize={textSize} color={textColor} mb={{ base: 4, md: 6, lg: 8 }} textAlign={{ base: "left", md: "center" }}>
            Regent NX Chairs offers best-in-class seating systems for your corporate environment, residence, or commercial places. Since 2000, our company designs and manufactures a vast collection of executive chairs, visitor chairs, office chairs, etc., that reflect integrity in design. Constant research has helped us develop a reputation in the corporate industry that lasts for many years, decades, and generations.
          </Text>
          <Text fontSize={textSize} color={textColor} mb={{ base: 4, md: 6, lg: 8 }} textAlign={{ base: "left", md: "center" }}>
            Through employing the most advanced machinery and skilled workers, we have been able to manufacture products that have earned us a reputation as leaders in the market.
          </Text>

          {/* Why Choose Our Seating Solutions */}
          <Box mt={{ base: 6, md: 8, lg: 12 }}>
            <Heading as="h2" size={{ base: "lg", md: "xl", lg: "xl" }} fontWeight="md" textAlign="center" mb={{ base: 4, md: 6, lg: 8 }} color={textColor}>
              Why Choose Our Seating Solutions
            </Heading>
            <List spacing={4} maxW="container.md" mx="auto" textAlign={{ base:"center" , md:"justify", lg:"justify"}} fontSize={textSize} color={textColor} mb={{ base: 4, md: 6, lg: 8 }}>
              <ListItem><b>Comprehensive Seating Solutions:</b> We offer complete seating solutions for corporate, residential, and commercial environments.</ListItem>
              <ListItem><b>Innovative Design:</b> Our products feature cutting-edge designs that blend aesthetics with functionality.</ListItem>
              <ListItem><b>Research-Based Comfort:</b> Each product is developed through extensive research to ensure maximum comfort and ergonomics.</ListItem>
              <ListItem><b>Competitive Pricing:</b> Enjoy high-quality seating solutions at competitive prices.</ListItem>
              <ListItem><b>Customization:</b> We provide tailored solutions to meet the specific needs and preferences of our customers.</ListItem>
              <ListItem><b>Exceptional Service:</b> Experience excellent packaging and timely delivery, ensuring that your orders arrive in perfect condition and on schedule.</ListItem>
            </List>
          </Box>

          {/* Quality Section */}
          <Box mt={{ base: 6, md: 8, lg: 12 }}>
            <Heading as="h2" size={{ base: "lg", md: "xl", lg: "xl" }} fontWeight="md" textAlign="center" mb={{ base: 4, md: 6, lg: 8 }} color={textColor}>
              Quality
            </Heading>
            <Text fontSize={textSize} color={textColor} mb={{ base: 4, md: 6, lg: 8 }} textAlign={{ base: "left", md: "center" }}>
              Perfection in designs and usage of best quality raw materials become the defining characteristics of our chairs.
            </Text>
            <Text fontSize={textSize} color={textColor} textAlign={{ base: "left", md: "center" }}>
              Our seating is specially designed to reduce body fatigue, increase work performance, and enhance the productivity of individuals in official and other environments. Our products are tested at all production processes by senior professionals and experts for a quality output.
            </Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default AboutScreen;
