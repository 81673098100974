import { Box, Text, VStack, Button, useBreakpointValue, useColorModeValue, Flex, Icon } from '@chakra-ui/react';
import { PiOfficeChairFill } from "react-icons/pi";
import { GiShakingHands } from "react-icons/gi";
import { useSpring, animated } from '@react-spring/web'; // Import react-spring
import { useInView } from 'react-intersection-observer'; // Import react-intersection-observer

const Banner7 = () => {
  const headingFontSize = useBreakpointValue({ base: '2xl', md: '2xl', lg: '3xl' });
  const paragraphFontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });

  const handleClick = () => {
    window.location.href = 'tel:+919004916011';
  };

  // Light and dark mode configurations
  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('black', 'whiteAlpha.800');
  const hoverTextColor = useColorModeValue('black', 'white');
  const buttonBg = useColorModeValue('black', 'white');
  const buttonHoverBg = useColorModeValue('gray.200', 'gray.700');
  const buttonTextColor = useColorModeValue('white', 'black');
  
   
  // Animation configurations with slower speed
  const projectsCompletedSpring = useSpring({
    number: 20000,
    from: { number: 0 },
    config: { duration: 3000 }, // Duration in milliseconds
    reset: true,
  });
  const yearsExperienceSpring = useSpring({
    number: 25,
    from: { number: 0 },
    config: { duration: 3000 }, // Duration in milliseconds
    reset: true,
  });


  // Intersection observer configuration
  const { ref: bannerRef, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the element is visible
  });

  return (
    <Box
      ref={bannerRef}
      position="relative"
      width="100%"
      height={{ base: 'auto', md: '70vh', lg: '100vh' }}
      backgroundColor={bgColor}
      padding={{ base: '4', md: '8' }}
      color={textColor}
      display="flex"
      alignItems="center"
      justifyContent="center"
      mt={{ base: '0', md: '-600', lg: '5' }}
    >
      <VStack
        spacing={4}
        align="center"
        justify="center"
        textAlign="center"
        maxWidth="800px"
      >
        <Text
          fontSize={headingFontSize}
          fontWeight="md"
          mb={4}
          letterSpacing="wide"
          _hover={{
            textDecoration: 'underline',
            color: hoverTextColor,
          }}
        >
          Experts for a Quality Output!
        </Text>
        <Text
          fontSize={paragraphFontSize}
          fontWeight="normal"
          lineHeight="tall"
          mb={6}
        >
          Best-in-class seating systems for your environment needs! Since 1993, our company designs and manufactures a vast collection of executive chairs, visitor chairs, office chairs, and more that reflect integrity in design. At Regent Chairs, we prioritize quality and comfort to ensure that our products meet the highest standards and enhance any workspace.
        </Text>
        <Text
          fontSize={paragraphFontSize}
          fontWeight="normal"
          mb={6}
          letterSpacing="wide"
        >
          Discover more about our range of seating solutions and experience the difference in your workspace. We are thrilled to announce that our online shopping platform will be launching soon, making it easier for you to explore and purchase our products from the comfort of your home.
        </Text>
        <Text
          fontSize={paragraphFontSize}
          fontWeight="normal"
          mb={6}
          letterSpacing="wide"
        >
          For bulk orders or personalized assistance, please don't hesitate to contact us. Our team is here to help you find the perfect seating solutions for your needs.
        </Text>
        <Button
          bg={buttonBg}
          color={buttonTextColor}
          borderRadius="none"
          border="1px solid"
          borderColor={buttonBg}
          px={2}
          py={2}
          fontSize={{
            base: '14px',
            sm: '14px',
            md: '16px',
            lg: '16px',
          }}
          onClick={handleClick}
          mt={{ base: '4', md: '8', lg: '10' }}
          _hover={{ bg: buttonHoverBg, color: hoverTextColor }}
          _active={{
            bg: buttonBg,
            transform: 'scale(0.95)',
          }}
          transition="transform 0.1s ease"
          letterSpacing="wide"
        >
          Call for Bulk Orders
        </Button>
        <Text
          fontSize={paragraphFontSize}
          fontWeight="normal"
          mt={6}
          letterSpacing="wide"
        >
          Stay tuned for our online shopping experience, coming soon! Check back with us to explore our full range of products and enjoy a seamless shopping experience.
        </Text>

        {/* Additional Box Components with Animated Numbers */}
        <Flex
          direction={{ base: 'row', md: 'row' }}
          spacing={{ base: 4, md: 8 }}
          align="center"
          justify="center"
          mb={20} // Space between the boxes and the bottom of the container
        >
          <Flex
            p={4}
            bg='transparent'
            textAlign="center"
            align="center"
            maxWidth="300px"
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            mr={{base:0, md: 4 }}
            mb={{ base: 0, md: 0 }}
            _hover={{ transform: 'scale(1.1)', transition: 'transform 0.2s' }} // Zoom effect on hover
          >
            <Icon mt={{base:'1', md:'-6'}}  mr={{base:'', md:'-6'}} as={PiOfficeChairFill} boxSize={50} color={useColorModeValue('black', 'white')} />
            <VStack align="center" spacing={1}>
              <Text fontSize="3xl" fontWeight="md">
                <animated.span>
                  {inView ? projectsCompletedSpring.number.to(n => `${n.toFixed(0)}+`) : '0+'}
                </animated.span>
              </Text>
              <Text mt='1' fontSize="md">
                Projects Completed
              </Text>
            </VStack>
          </Flex>
          <Flex
            p={4}
            bg='transparent'
            textAlign="center"
            align="center"
            maxWidth="300px"
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            ml={{ md: 4 }}
            _hover={{ transform: 'scale(1.1)', transition: 'transform 0.2s' }} // Zoom effect on hover
          >
            <Icon as={GiShakingHands} mt={{base:'0', md:'-7'}} mr={{base:'', md:'-10'}} boxSize={55} color={useColorModeValue('black', 'white')} />
            <VStack align="center" spacing={1}>
              <Text fontSize="3xl" fontWeight="md">
                <animated.span>
                  {inView ? yearsExperienceSpring.number.to(n => `${n.toFixed(0)}+`) : '0+'}
                </animated.span>
              </Text>
              <Text mt='1' fontSize="md">
                Years of Experience
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Banner7;
