import { Grid, Box, Heading, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ProductCard from '../components/ProductCard';

const LatestCollectionScreen = () => {
	const dispatch = useDispatch();
	const [reloadKey, setReloadKey] = useState(0); 
	const productList = useSelector((state) => state.productList);
	const { loading, products, error } = productList;

	useEffect(() => {
		dispatch(listProducts());
	}, [dispatch]);

	
	useEffect(() => {
		dispatch(listProducts());
	}, [dispatch, reloadKey]);

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				setReloadKey(prevKey => prevKey + 1); 
			}, 500); 

			return () => clearTimeout(timer); 
		}
	}, [error]);

	useEffect(() => {
		document.title = 'Latest Collection | REGENT NX CHAIRS';
	  }, []);

	
	const sortedProducts = Array.isArray(products)
		? products.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
		: [];
	
	
	const uniqueProducts = sortedProducts.filter((prod, index, self) =>
		index === self.findIndex((p) => p.name === prod.name)
	);

	
	const latestProducts = uniqueProducts.slice(0, 50);

	return (
		<Box mt={{ base: '120', md: '100', lg: '180' }} px={{ base: '4', md: '8', lg: '12' }}>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<>
					<Heading
						as='h2'
						size='lg'
						mb={4}
						fontWeight='semibold'
						fontSize={{
							base: '20px', 
							sm: '24px',   
							md: '28px',   
							lg: '32px',   
						}}
					>
						Latest Collection
					</Heading>
					
					<Grid
						templateColumns={{
							base: 'repeat(2, 1fr)', 
							sm: 'repeat(3, 1fr)',   
							lg: 'repeat(4, 1fr)'    
						}}
						gap={{ base: '0', sm: '0', md: '0', lg: '0' }} 
					>
						{latestProducts.length === 0 ? (
							<Message>No products found</Message>
						) : (
							latestProducts.map((prod) => (
								<ProductCard key={prod._id} product={prod} />
							))
						)}
					</Grid>
				</>
			)}
		</Box>
	);
};

export default LatestCollectionScreen;
