import {
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Link,
	Spacer,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { getUserDetails, updateUser } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { USER_UPDATE_RESET } from '../constants/userConstants';

const UserEditScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: userId } = useParams();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userUpdate = useSelector((state) => state.userUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = userUpdate;

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: USER_UPDATE_RESET });
			navigate(`/admin/userlist`);
		} else {
			if (!user.name || user._id !== userId) {
				dispatch(getUserDetails(userId));
			} else {
				setName(user.name);
				setEmail(user.email);
				setIsAdmin(user.isAdmin);
			}
		}
	}, [user, dispatch, userId, successUpdate, navigate]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(updateUser({ _id: userId, name, email, isAdmin }));
	};

	const formBg = useColorModeValue('white', 'gray.800');
	const formColor = useColorModeValue('gray.900', 'black');
	const inputBg = useColorModeValue('gray.100', 'gray.500');
	const inputColor = useColorModeValue('black', 'white');
	const linkColor = useColorModeValue('black', 'white');
	const buttonColorScheme = useColorModeValue('teal', 'orange');

	return (
		<>
			<Link as={RouterLink} to='/admin/userlist' color={linkColor} mt={{base: '100px',md: '130px', lg:'40'}} >
				Go Back
			</Link>
			<Flex w='full' alignItems='center' justifyContent='center' py='5'>
				<FormContainer bg={formBg} color={formColor}>
					<Heading as='h1' mt={{base: '-10',md: '-20', lg:'-20'}} mb='8' fontSize='3xl' color={formColor}>
						Edit User
					</Heading>

					{loadingUpdate && <Loader />}
					{errorUpdate && <Message type='error'>{errorUpdate}</Message>}

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<form onSubmit={submitHandler}>
							<FormControl id='name' isRequired>
								<FormLabel color={formColor}>Name</FormLabel>
								<Input
									type='text'
									placeholder='Enter full name'
									value={name}
									onChange={(e) => setName(e.target.value)}
									bg={inputBg}
									color={inputColor}
								/>
							</FormControl>
							<Spacer h='3' />

							<FormControl id='email' isRequired>
								<FormLabel color={formColor}>Email Address</FormLabel>
								<Input
									type='text'
									placeholder='Enter email address'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									bg={inputBg}
									color={inputColor}
								/>
							</FormControl>
							<Spacer h='3' />

							<FormControl id='isAdmin' isRequired>
								<FormLabel color={formColor}>Is Admin?</FormLabel>
								<Checkbox
									size='lg'
									color='black'
									borderColor='black'
									checked={isAdmin}
									onChange={(e) => setIsAdmin(e.target.checked)}
								>
									Is Admin?
								</Checkbox>
							</FormControl>
							<Spacer h='3' />

							<Button
								type='submit'
								isLoading={loading}
								colorScheme={buttonColorScheme}
								mt='4'
							>
								Update
							</Button>
						</form>
					)}
				</FormContainer>
			</Flex>
		</>
	);
};

export default UserEditScreen;
