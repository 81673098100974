import { Box, Image, Text, useBreakpointValue, Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import image1 from '../Assets/images/banner1.jpg';

const Banner1 = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setScrollY(scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const imageSize = useBreakpointValue({ base: '50vh', md: '70vh',lg:'100vh' });
  const imageHeight = parseFloat(imageSize);

  const translateY = scrollY > imageHeight ? (scrollY - imageHeight) * -0.5 : 0;
  const scale = scrollY > imageHeight ? 1 - (scrollY - imageHeight) / 2000 : 1;

  return (
    <Link as={RouterLink} to="/latest" _hover={{ textDecoration: 'none' }}>
      <Box
        width="100%"
        height={imageSize}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        position="relative"
        transition="transform 0.5s ease"
        transform={`translateY(${translateY}px) scale(${scale})`}
        cursor="pointer"
      >
        <Image
          src={image1}
          alt="Banner 1"
          objectFit="cover"
          width="100%"
          height="100%"
          filter="brightness(100%)"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          background="linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))"
          pointerEvents="none"
        />
        <Text
          position="absolute"
          color="blackAlpha.900"
          fontSize={{ base: '18px', md: '36px' }}
          fontWeight="500"
          textAlign="center"
          transition="all 0.5s ease" 
          _hover={{
            transform: 'scale(1.1)', 
            letterSpacing: '0.05em', 
          }}
        >
          See Our Latest Collection
        </Text>
      </Box>
    </Link>
  );
};

export default Banner1;
