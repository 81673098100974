import { Link, Box, Image, Text, SimpleGrid, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import image6 from '../Assets/images/visitor.jpg'; 
import image7 from '../Assets/images/cafeteria.jpg';   
import image8 from '../Assets/images/tandem2.jpg'; 

const Banner4 = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + windowHeight > documentHeight - 200); 
    setScrollY(scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const imageSize = useBreakpointValue({ base: '45vh', md: '30vh',lg:'80vh' });

  const navigate = useNavigate(); 

  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('black', 'whiteAlpha.800');
  const hoverTextColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <Box position="relative" width="100%" height={{ base: 'auto', md: '100vh' }}>
      {/* Explore More Text */}
      <Text
        fontSize={{ base: 'lg', md: 'xl' }} 
        fontWeight="md"
        letterSpacing='wide'
        color={textColor}
        textAlign="center"
        mb={4}
        p={4}
        mt={{base:'-10',md:'-12',lg:'0'}}
        backgroundColor={bgColor}
        boxShadow="none"
        position="absolute"
        top="0"
        width="100%"
        zIndex="2" 
        _hover={{
          textDecoration: 'underline', color: hoverTextColor,
        }}
      >
        Explore More from Our Categories
      </Text>

      {/* Image Grid */}
      <SimpleGrid
        columns={{ base: 2, md: 3 }} 
        spacing={0} 
        position="relative" 
        mt={{ base: '4rem', md: '0rem',lg:'4rem' }} 
      >
        {/* Image 1 */}
        <Box
          height={imageSize} 
          position="relative"
          overflow="hidden" 
          transition="transform 0.5s ease, box-shadow 0.5s ease" 
          transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1})`} 
          _hover={{
            transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1.05})`, 
            boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)', 
          }}
        >
          <Link onClick={() => navigate('products/Visitors')} width="100%" height="100%">
            <Image
              src={image6}
              alt="Visitor Chairs"
              objectFit="cover"
              width="100%"
              height="100%"
              filter="brightness(100%)"
            />
          </Link>
          <Link onClick={() => navigate('products/Visitors')} position="absolute" width="100%" height="100%" top="0" left="0" />
          <Text
              fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
             letterSpacing='wider'
             fontWeight="semibold"
             color="white"
             position="absolute"
             bottom={{base:'7%',md:'5%', lg:'8%'}}
             left="5%"
             p="2"
             background="rgba(0, 0, 0, 0.4)"
             borderRadius="md"
             maxWidth="80%"
             zIndex="1"
             boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
             textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
            transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
            _hover={{
              transform: 'translateY(-15px)',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
            }}
          >
            <Link onClick={() => navigate('/products/Visitors')} color="white" _hover={{ textDecoration: 'none' }}>
              VISITOR CHAIRS
            </Link>
          </Text>
        </Box>

        {/* Image 2 */}
        <Box
          height={imageSize} 
          position="relative"
          overflow="hidden" 
          transition="transform 0.5s ease, box-shadow 0.5s ease" 
          transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1})`} 
          _hover={{
            transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1.05})`, 
            boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)', 
          }}
        >
          <Link onClick={() => navigate('/products/CafeteriaOutdoor')} width="100%" height="100%">
            <Image
              src={image7}
              alt="Modern Chairs"
              objectFit="cover"
              width="100%"
              height="100%"
              filter="brightness(100%)"
            />
          </Link>
          <Link onClick={() => navigate('/products/CafeteriaOutdoor')} position="absolute" width="100%" height="100%" top="0" left="0" />
          <Text
             fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
             letterSpacing='wider' 
            fontWeight="semibold"
            color="white"
            position="absolute"
            bottom={{base:'5%',md:'2%', lg:'8%'}}
            left="5%"
            p="2"
            background="rgba(0, 0, 0, 0.4)"
            borderRadius="md"
            maxWidth="80%"
            zIndex="1"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
            textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
           transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
           _hover={{
             transform: 'translateY(-15px)',
             textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
           }}
          >
            <Link onClick={() => navigate('/products/CafeteriaOutdoor')} color="white" _hover={{ textDecoration: 'none' }}>
              CAFETERIA & OUTDOOR CHAIRS
            </Link>
          </Text>
        </Box>

        {/* Image 3 */}
        <Box
          height={['50vh', '70vh', 'auto']} 
          width={["200%", "200%", "100%"]} 
          position="relative"
          overflow="hidden" 
          transition="transform 0.5s ease, box-shadow 0.5s ease" 
          transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1})`} 
          _hover={{
            transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 - (scrollY / 1000) * 0.2 : 1.05})`, 
            boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)', 
          }}
        >
          <Link onClick={() => navigate('/products/TandemSeating')} width="100%" height="100%">
            <Image
              src={image8}
              alt="Tandem Seating"
              objectFit={{base:'cover',  lg:'fill'}}
              width="100%"
              height="100%"
              filter="brightness(100%)"
            />
          </Link>
          <Link onClick={() => navigate('/products/TandemSeating')} position="absolute" width="100%" height="100%" top="0" left="0" />
          <Text
           fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
          letterSpacing='wider'
           fontWeight="semibold"
           color="white"
           position="absolute"
           bottom={{base:'10%',md:'5%', lg:'8%'}}
           left="5%"
           p="2"
           background="rgba(0, 0, 0, 0.4)"
           borderRadius="md"
           maxWidth="80%"
           zIndex="1"
           boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
           textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
          transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
          _hover={{
            transform: 'translateY(-15px)',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
          }}
          >
            <Link onClick={() => navigate('/products/TandemSeating')} color="white" _hover={{ textDecoration: 'none' }}>
              TANDEM SEATING
            </Link>
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Banner4;
