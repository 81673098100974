import {
	Button,
	Flex,
	FormControl,
	Input,
	IconButton,
	InputGroup,
	InputRightElement,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { login } from '../actions/userActions';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const redirect = searchParams.get('redirect') || '/';

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState({ email: '', password: '' });
	const [loginError, setLoginError] = useState('');

	const userLogin = useSelector((state) => state.userLogin);
	const { loading, userInfo, error: loginFailError } = userLogin;

	const bgColor = useColorModeValue('white', 'black');
	const inputBorderColor = useColorModeValue('gray', 'gray');
	const inputFocusBorderColor = useColorModeValue('black', 'gray.100');
	const textColor = useColorModeValue('gray.800', 'whiteAlpha.800');
	const placeholderColor = useColorModeValue('gray.500', 'whiteAlpha.800');

	useEffect(() => {
		document.title = 'Login | REGENT NX CHAIRS';
	  }, []);

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [userInfo, navigate, redirect]);

	useEffect(() => {
		if (loginFailError) {
			setLoginError('Invalid email or password');
		}
	}, [loginFailError]);

	const submitHandler = (e) => {
		e.preventDefault();
		let hasError = false;
		const newError = { email: '', password: '' };

		if (!email) {
			newError.email = 'Please enter your email.';
			hasError = true;
		}
		if (!password) {
			newError.password = 'Please enter your password.';
			hasError = true;
		}

		setError(newError);

		if (!hasError) {
			dispatch(login(email, password));
		}
	};

	const handlePasswordToggle = () => setShowPassword(!showPassword);

	return (
		<Flex w="full" h="100vh" p={4} bg={bgColor} align="center" justify="center">
			<Flex
				direction={{ base: 'column', md: 'row' }}
				maxW="1000px"
				mx="auto"
				w="full"
				h="full"
				p={8}
				bg={bgColor}
			>
				{/* Login Section */}
				<Flex
					direction="column"
					ml={{ base: '-14', md: '-55', lg: '-65' }}
					align="flex-start"
					mt={{ base: 20, md: '0', lg: '0' }}
					justify="center"
					w={{ base: '90%', md: '50%' }}
					p={6}
				>
					<Heading as="h1" mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'lg' }} fontWeight="normal" color={textColor}>
						LOG IN TO YOUR ACCOUNT
					</Heading>

					{loginError && (
						<Text fontSize="sm" color="red.500" mb={4}>
							{loginError}
						</Text>
					)}

					<form onSubmit={submitHandler}>
						<FormControl id="email" width="400px" mb={4}>
							<Input
								id="email"
								type="email"
								placeholder="E-MAIL"
								fontSize="sm"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={error.email ? 'red.500' : inputBorderColor}
								_placeholder={{ color: placeholderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{error.email && (
								<Text fontSize="sm" color="red.500" mt={1}>
									{error.email}
								</Text>
							)}
						</FormControl>

						<FormControl id="password" mb={14}>
							<InputGroup>
								<Input
									id="password"
									type={showPassword ? 'text' : 'password'}
									placeholder="PASSWORD"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									size="xl"
									fontSize="sm"
									letterSpacing="wide"
									border="none"
									borderRadius="none"
									borderBottom="1px solid"
									borderBottomColor={error.password ? 'red.500' : inputBorderColor}
									_placeholder={{ color: placeholderColor }}
									_focus={{
										borderBottomColor: inputFocusBorderColor,
										outline: 'none',
										boxShadow: 'none',
									}}
									_hover={{ borderBottomColor: inputFocusBorderColor }}
									paddingBottom="0px"
									bg="transparent"
								/>
								<InputRightElement>
									<IconButton
										aria-label={showPassword ? 'Hide password' : 'Show password'}
										icon={showPassword ? <FaEyeSlash /> : <FaEye />}
										variant="link"
										onClick={handlePasswordToggle}
										size="sm"
										color={placeholderColor}
									/>
								</InputRightElement>
							</InputGroup>
							{error.password && (
								<Text fontSize="sm" color="red.500" mt={1}>
									{error.password}
								</Text>
							)}
						</FormControl>

						<Button
							type="submit"
							size="md"
							ml={{ base: '-1', md: '0', lg: '0' }}
							isLoading={loading}
							borderRadius="none"
							width="full"
							mb={6}
							bg={useColorModeValue('black', 'gray.100')}
							color={useColorModeValue('white', 'black')}
							letterSpacing="wide"
							fontSize={{ base: 'md', md: 'md', lg: 'md' }}
							_hover={{ bg: useColorModeValue('gray.800', 'gray.600') }}
							_active={{
								bg: useColorModeValue('black', 'gray.700'),
								color: 'white',
								transform: 'scale(0.95)',
							}}
						>
							LOGIN
						</Button>
					</form>
				</Flex>

				{/* Registration Section */}
				<Flex
					direction={{ base: 'column', md: 'row' }}
					align="center"
					justify="center"
					ml={{ base: '0', md: '100', lg: '5' }}
					w={{ base: '100%', md: '50%' }}
					p={6}
					borderLeft={{ base: 'none', md: `1px solid ${useColorModeValue('#e1e1e1', 'white')}` }}
					mt={{ base: '4', md: '0' }}
				>
					<Heading as="h2" mb={8} fontSize={{ base: 'md', md: 'md', lg: 'lg' }} mr={{ base: '0', md: '-200', lg: '-220' }}  mt={{ base: '4', md: '-100', lg: '-100' }} letterSpacing="wide" fontWeight="normal" color={textColor}>
						MAKE YOUR NEW ACCOUNT
					</Heading>
					<Button
						as={RouterLink}
						to="/register"
						size="md"
						fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
						letterSpacing="wide"
						borderRadius="none"
						width="200px"
						bg={useColorModeValue('black', 'gray.100')}
						color={useColorModeValue('white', 'black')}
						_hover={{ bg: useColorModeValue('gray.800', 'gray.600') }}
						_active={{
							bg: useColorModeValue('black', 'gray.700'),
							color: 'white',
							transform: 'scale(0.95)',
						}}
					>
						REGISTER
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default LoginScreen;
