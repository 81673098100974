import {
	Button,
	Flex,
	FormControl,
	Input,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { register } from '../actions/userActions';

const RegisterScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	let redirect = searchParams.get('redirect') || '/';

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [errors, setErrors] = useState({});

	const userRegister = useSelector((state) => state.userRegister);
	const { loading, error, userInfo } = userRegister;

	const bgColor = useColorModeValue('white', 'black');
	const inputBorderColor = useColorModeValue('gray', 'gray.100');
	const inputFocusBorderColor = useColorModeValue('black', 'gray.100');
	const textColor = useColorModeValue('gray.800', 'whiteAlpha.800');
	const placeholderColor = useColorModeValue('gray.500', 'whiteAlpha.800');

	useEffect(() => {
		document.title = 'Register | REGENT NX CHAIRS';
	  }, []);

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, userInfo, redirect]);

	const validateFields = () => {
		const newErrors = {};
		if (!name) newErrors.name = 'Name is required';
		if (!email) newErrors.email = 'Email is required';
		if (!phone) {
			newErrors.phone = 'Phone number is required';
		} else if (!/^\d{10}$/.test(phone)) {
			newErrors.phone = 'Invalid Phone number';
		}
		if (!password) newErrors.password = 'Password is required';
		else if (password.length < 8) newErrors.password = 'Password must be at least 8 characters long';
		if (!confirmPassword) newErrors.confirmPassword = 'Confirm Password is required';
		else if (password && confirmPassword && password !== confirmPassword)
			newErrors.confirmPassword = 'Passwords do not match';
		return newErrors;
	};

	const submitHandler = (e) => {
		e.preventDefault();
		const fieldErrors = validateFields();
		if (Object.keys(fieldErrors).length > 0) {
			setErrors(fieldErrors);
		} else {
			setErrors({});
			dispatch(register(name, email, phone, password));
		}
	};

	const getBorderColor = (field) => (errors[field] ? 'red.500' : 'gray');

	return (
		<Flex w="full" h="100vh" p={4} bg={bgColor}align="center" justify="center">
			<Flex
				direction={{ base: 'column', md: 'row' }}
				maxW="1000px"
				mx="auto"
				w="full"
				h="full"
				p={8}
				bg={bgColor}
			>
				{/* Register Section */}
				<Flex
					direction="column"
					ml={{ base: '-14', md: '-55', lg: '-65' }}
					align="flex-start"
					mt={{ base: '20', md: '0', lg:'30' }}
					justify="center"
					w={{ base: '90%', md: '50%' }}
					p={6}
				>
					<Heading as="h1" mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'lg' }} fontWeight="normal" color={textColor}>
						CREATE YOUR ACCOUNT
					</Heading>

					{error && (
						<Text fontSize="sm" color="red.500" mb={4}>
							{error}
						</Text>
					)}

					<form onSubmit={submitHandler}>
						<FormControl id="name" width="400px" mb={0}>
							<Input
								id="name"
								type="text"
								placeholder="NAME"
								maxLength={14}
								fontSize="sm"
								value={name}
								onChange={(e) => setName(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('name')}
								_placeholder={{ color: errors.name ? 'red.500' : placeholderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.name && (
								<Text fontSize="sm" color="red.500">
									{errors.name}
								</Text>
							)}
						</FormControl>

						<FormControl id="email" width="400px" mb={0}>
							<Input
								id="email"
								type="email"
								placeholder="E-MAIL"
								fontSize="sm"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('email') }
								_placeholder={{ color: errors.email ? 'red.500' : placeholderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.email && (
								<Text fontSize="sm" color="red.500">
									{errors.email}
								</Text>
							)}
						</FormControl>

						<FormControl id="phone" width="400px" mb={0}>
							<Input
								id="phone"
								type="text"
								placeholder="PHONE NUMBER"
								fontSize="sm"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('phone')}
								_placeholder={{ color: errors.phone ? 'red.500' : placeholderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.phone && (
								<Text fontSize="sm" color="red.500">
									{errors.phone}
								</Text>
							)}
						</FormControl>

						<FormControl id="password" width="400px" mb={0}>
							<Input
								id="password"
								type="password"
								placeholder="PASSWORD"
								fontSize="sm"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('password')}
								_placeholder={{ color: errors.password ? 'red.500' : inputBorderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.password && (
								<Text fontSize="sm" color="red.500">
									{errors.password}
								</Text>
							)}
						</FormControl>

						<FormControl id="confirmPassword" width="400px" mb={0}>
							<Input
								id="confirmPassword"
								type="password"
								placeholder="CONFIRM PASSWORD"
								fontSize="sm"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('confirmPassword')}
								_placeholder={{ color: errors.confirmPassword ? 'red.500' : inputBorderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.confirmPassword && (
								<Text fontSize="sm" color="red.500">
									{errors.confirmPassword}
								</Text>
							)}
						</FormControl>

						<Button
							type="submit"
							size="md"
							isLoading={loading}
							borderRadius="none"
							width="full"
							ml={{ base: '-2', md: '0', lg: '0' }}
							mb={-32}
							bg={useColorModeValue('black', 'gray.100')}
							color={useColorModeValue('white', 'black')}
							letterSpacing="wide"
							fontSize={{ base: 'md', md: 'md', lg: 'md' }}
							_hover={{ bg: useColorModeValue('gray.800', 'gray.600') }}
							_active={{
								bg: useColorModeValue('black', 'gray.700'),
								color: 'white',
								transform: 'scale(0.95)',
							}}
						>
							REGISTER
						</Button>
					</form>
				</Flex>

				{/* Log In Section */}
				<Flex
						direction="column"
						align="center"
						justify="center"
						ml={{ base: '0', md: '100', lg: '5' }}
						w={{ base: 'full', md: '50%' }}
						p={6}
						borderLeft={{ base: 'none', md: '1px solid #e1e1e1' }}
						mt={{ base: '4', md: '0' }}
				>
					<Heading as="h2" mb={8} fontSize={{ base: 'md', md: 'md', lg: 'lg' }} mr={{ base: '0', md: '-8', lg: '0' }} mt={{ base: '55', md: '-170', lg: '-170' }} letterSpacing="wide" fontWeight="normal" color={textColor}>
						ALREADY HAVE AN ACCOUNT?
					</Heading>
					<Button
						as={RouterLink}
						to="/login"
						size="md"
						fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
						letterSpacing="wide"
						borderRadius="none"
						width="200px"
						bg={useColorModeValue('black', 'gray.100')}
						color={useColorModeValue('white', 'black')}
						_hover={{ bg: useColorModeValue('gray.800', 'gray.600') }}
						_active={{
							bg: useColorModeValue('black', 'gray.700'),
							color: 'white',
							transform: 'scale(0.95)',
						}}
					>
						Log In
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default RegisterScreen;
