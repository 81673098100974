import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Text,
	useBreakpointValue,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoCheckmarkCircleSharp,
	IoCloseCircleSharp,
	IoPencilSharp,
	IoTrashBinSharp,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { deleteUser, listUsers } from '../actions/userActions';
import Loader from '../components/Loader';
import Message from '../components/Message';

const UserListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success } = userDelete;

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listUsers());
		} else {
			navigate('/login');
		}
	}, [dispatch, navigate, userInfo, success]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id));
		}
	};

	useEffect(() => {
		document.title = 'User List | REGENT NX CHAIRS';
	  }, []);

	const headingSize = useBreakpointValue({ base: 'xl', md: '2xl',lg:'3xl' });
	const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
	const tableVariant = useBreakpointValue({ base: 'simple', md: 'simple' });
	const boxPadding = useBreakpointValue({ base: '4', md: '6' });
	const marginTop = useBreakpointValue({ base: '100', md: '150' });

	const bgColor = useColorModeValue('gray.50', 'gray.700'); 
	const textColor = useColorModeValue('black', 'whiteAlpha.800'); 
	const tableColorScheme = useColorModeValue('gray', 'whiteAlpha'); 

	return (
		<>
			<Heading
				as='h1'
				fontSize={headingSize}
				fontWeight='md'
				mb='5'
				textAlign='center'
				color={textColor}
				mt={marginTop}
			>
				User List
			</Heading>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Box
					bgColor={bgColor}
					rounded='lg'
					shadow='lg'
					p={boxPadding}
					overflowX='auto'
					mx={{ base: '2', md: 'auto' }}
					overflowY='auto'
				>
					<Table variant={tableVariant} colorScheme={tableColorScheme} size='md'>
						<Thead>
							<Tr>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>ID</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>NAME</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>EMAIL</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>PHONE</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>ADMIN</Th>
								<Th color={textColor} textAlign='right' fontSize={{ base: 'sm', md: 'md' }}>ACTIONS</Th>
							</Tr>
						</Thead>
						<Tbody>
							{users.map((user) => (
								<Tr key={user._id}>
									<Td fontSize={{ base: 'sm', md: 'md' }}>{user._id}</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }}>{user.name}</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }}>
										<Text
											as='a'
											href={`mailto:${user.email}`}
											color={textColor}
											isExternal
										>
											{user.email}
										</Text>
									</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }}>
										<Text
											as='a'
											href={`tel:${user.phone}`}
											color={textColor}
											isExternal
										>
											{user.phone}
										</Text>
									</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }}>
										{user.isAdmin ? (
											<Icon
												as={IoCheckmarkCircleSharp}
												color='green.500'
												w='5'
												h='5'
											/>
										) : (
											<Icon
												as={IoCloseCircleSharp}
												color='red.500'
												w='5'
												h='5'
											/>
										)}
									</Td>
									<Td>
										<Flex
											justifyContent='flex-end'
											alignItems='center'
											flexDirection={{ base: 'column', md: 'row' }}
											gap='2'
										>
											<Button
												as={RouterLink}
												to={`/admin/user/${user._id}/edit`}
												colorScheme='teal'
												variant='solid'
												size={buttonSize}
											>
												<Icon as={IoPencilSharp} mr='2' />
												Edit
											</Button>
											<Button
												colorScheme='red'
												variant='outline'
												size={buttonSize}
												onClick={() => deleteHandler(user._id)}
											>
												<Icon as={IoTrashBinSharp} mr='2' />
												Delete
											</Button>
										</Flex>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			)}
		</>
	);
};

export default UserListScreen;
