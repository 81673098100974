
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'black' : 'white',
        color: props.colorMode === 'dark' ? 'whiteAlpha.900' : 'black',
        fontFamily:'Helvetica, sans-serif', 
      },
    }),
  },
  
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },

  components: {
    Text: {
      baseStyle: {
        cursor: 'pointer',
        fontSize: 'sm',
        fontWeight: 'md',
        letterSpacing: 'wide',
        
      },
    },
    
  },
});

export default theme;
