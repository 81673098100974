import { Grid, Box, Heading, Text, useColorModeValue, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listProducts } from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ProductCard from '../components/ProductCard';

const ProductDisplayScreen = () => {
	const { category: urlCategory } = useParams();
	const dispatch = useDispatch();
	const [category, setCategory] = useState(urlCategory || '');
	const [retryCount, setRetryCount] = useState(0); 

	const productList = useSelector((state) => state.productList);
	const { loading, products = [], error } = productList;

	const headingColor = useColorModeValue('gray.800', 'whiteAlpha.900');
	const textColor = useColorModeValue('blackAlpha.900', 'whiteAlpha.800');
	const bgColor = useColorModeValue('white', 'black');

	useEffect(() => {
		setCategory(urlCategory || '');
	}, [urlCategory]);

	useEffect(() => {
		let retryTimeout;

		if (error && retryCount < 3) {
			retryTimeout = setTimeout(() => {
				setRetryCount((prevCount) => prevCount + 1);
				dispatch(listProducts());
			}, 500); 
		}

		return () => {
			clearTimeout(retryTimeout);
		};
	}, [error, retryCount, dispatch]);
	
	const filteredProducts = category
		? products
			.filter((product) => product.category === category)
			.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
		: [];

		const categoryHeaders = {
			Executive: 'Executive Chairs',
			ErgonomicOffice: 'Ergonomic Office Chairs',
			Gaming: 'Gaming Chairs',
			Workstation: 'Work Station Chairs',
			Conference: 'Conference Chairs',
			Staff: 'Staff Chairs',
			Visitors: 'Visitors Chairs',
			TandemSeating: 'Tandem Seating Solutions',
			Training: 'Training Chairs',
			CafeteriaOutdoor: 'Cafeteria and Outdoor Chairs',
			LoungeChairs: 'Lounge Chairs'
		};

		const categoryDescriptions = {
			Executive: 'Experience the pinnacle of luxury and comfort with our Executive Chairs. Expertly crafted with premium leather and plush memory foam cushioning, these chairs provide exceptional support and sophistication. Designed for those who value both functionality and style, they feature adjustable lumbar support and a smooth reclining mechanism, ensuring optimal relaxation and support throughout your workday. Ideal for top executives, our chairs enhance productivity while making a bold statement in any office setting.',
			ErgonomicOffice: 'Elevate your workspace with our Ergonomic Office Chairs, meticulously designed to enhance comfort and support during long hours. Crafted with breathable mesh fabric and equipped with adjustable seat height, depth, and backrest tilt, these chairs ensure optimal posture and alleviate strain. Their ergonomic design fosters customized comfort, promoting a healthier work environment and boosting productivity.',
			Gaming: 'Elevate your gaming experience with our state-of-the-art Gaming Chairs. Built with high-density foam padding, a sturdy metal frame, and a full 360-degree swivel, these chairs are designed for maximum durability and comfort during long gaming sessions. The ergonomic design includes lumbar and headrest pillows, ensuring you stay comfortable while dominating your opponents.',
			Workstation: 'Workstation Chairs are engineered for peak productivity, offering ergonomic support with adjustable settings that cater to your specific needs. The mesh backrest provides breathability, while the cushioned seat ensures comfort during long hours at your desk. Ideal for open-plan offices or home workspaces.',
			Conference: 'Our Conference Chairs combine sleek design with exceptional comfort, making them perfect for boardrooms and meeting spaces. Upholstered in fine fabric or leather, these chairs offer a professional appearance with padded armrests and a smooth-rolling base for easy maneuverability during collaborative sessions.',
			Staff: 'Staff Chairs are designed with practicality and comfort in mind, perfect for everyday office use. These chairs feature durable upholstery, a robust build, and essential ergonomic adjustments to ensure that your staff stays comfortable and productive throughout the day.',
			Visitors: 'Visitors Chairs are designed to leave a lasting impression on your guests, combining sleek aesthetics with durable construction. The cushioned seats and supportive backs ensure comfort during short meetings or waiting periods, while the modern design adds a touch of elegance to any reception area.',
			TandemSeating: 'Tandem Seating solutions are perfect for high-traffic waiting areas, such as airports, hospitals, or office lobbies. Built with robust materials and designed for durability, these seats offer both comfort and longevity, with a design that accommodates multiple users without sacrificing style.',
			Training: 'Training chairs are essential for educational and professional environments, offering a blend of comfort, functionality, and durability. Designed to support long hours of sitting, these chairs often feature ergonomic designs with lumbar support, breathable materials, and adjustable components to accommodate various user preferences. Many training chairs come with added features such as foldable seats, attached writing tablets, and stackable designs for easy storage. Ideal for classrooms, corporate training rooms, seminars, and workshops, training chairs help create a conducive learning environment by ensuring participants remain comfortable and focused throughout their sessions.',
			CafeteriaOutdoor: 'Our Cafeteria and Outdoor Chairs are designed for durability and style, crafted from weather-resistant materials that make them perfect for both indoor and outdoor settings. These chairs are easy to clean and stackable for convenient storage, making them ideal for cafeterias, patios, and garden areas.',
			LoungeChairs: 'Lounge chairs are the epitome of comfort and style, designed for relaxation in both residential and commercial settings. These chairs often feature deep seats, plush cushioning, and ergonomic designs, providing the perfect spot to unwind. Available in various materials such as leather, fabric, and wood, lounge chairs can be both functional and aesthetically pleasing. Whether placed in a living room, office, or hotel lobby, lounge chairs add a touch of luxury and sophistication to any space, making them an ideal choice for those who prioritize comfort without compromising on style.'
		};
		  
		  const header = categoryHeaders[category] || '';
		  const description = categoryDescriptions[category] || '';

		  useEffect(() => {
			document.title = `${header} | REGENT NX CHAIRS`;
		}, [header]);


	return (
		<Box bg={bgColor}  mt={{ base: '110', md: '150', lg: '160' }} px={{ base: '4', md: '8', lg: '12' }}>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<>
					{header && (
						<Box mb={8}>
							<Heading
								as='h2'
								size='lg'
								letterSpacing='wide'
								mb={4}
								fontWeight='md'
								color={headingColor} 
								fontSize={{
									base: '20px', 
									sm: '24px',   
									md: '28px',   
									lg: '32px',   
								}}
							>
								{header}
							</Heading>
							<Text
								fontSize={{
									base: '14px', 
									sm: '16px',   
									md: '18px',   
									lg: '18px',   
								}}
								color={textColor} 
								letterSpacing='wide'
							>
								{description}
							</Text>
						</Box>
					)}
					<Grid
						templateColumns={{
							base: 'repeat(2, 1fr)', 
							sm: 'repeat(3, 1fr)',   
							lg: 'repeat(4, 1fr)'    
						}}
						gap={{ base: '0', sm: '0', md: '0', lg: '' }} 
					>
						{filteredProducts.length === 0 ? (
							<Message>No products found in this category</Message>
						) : (
							filteredProducts.map((prod) => (
								<ProductCard key={prod._id} product={prod} />
							))
						)}
					</Grid>
				</>
			)}
		</Box>
	);
};

export default ProductDisplayScreen;