import { Box, Flex, Text, Input, Image, useBreakpointValue, VStack, IconButton,  useColorMode, useColorModeValue  } from '@chakra-ui/react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { HiOutlineMenuAlt3, HiX,  HiSearch } from 'react-icons/hi';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { logout } from '../actions/userActions';
import { motion } from 'framer-motion';
import HeaderMenuItem from './HeaderMenuItem';
import UserMenu from './UserMenu';
import AdminMenu from './AdminMenu';
import Logo from './Logo.js';
import SocialLinks from './SocialLinks.js';
import { listProducts } from '../actions/productActions';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [activeCategory, setActiveCategory] = useState('chairs'); 
  const [searchTerm, setSearchTerm] = useState(''); 
  const [showDropdown, setShowDropdown] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [scrollY, setScrollY] = useState(0); 
  const [scrollDirection, setScrollDirection] = useState('up');
  const [isAtTop, setIsAtTop] = useState(true);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const iconColor = useColorModeValue('black', 'white');
  const bgColor = useColorModeValue('rgba(255, 255, 255, 0.97)', 'rgba(0, 0, 0, 0.9)');
  const borderColor = useColorModeValue('#eaeaea', 'white');
  const searchBorder = useColorModeValue('black', 'white');
  const placeholderColor = useColorModeValue('black', 'gray.400');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.900'); 

  const productList = useSelector((state) => state.productList);
	const { products} = productList;


  const menuRef = useRef();
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  const menuVariants = {
    open: {
      opacity: 1,
      height: 'auto',
      visibility: 'visible',
      transition: {
        duration: 0.4,
        ease: 'easeInOut'
      }
    },
    closed: {
      opacity: 0,
      height: 0,
      visibility: 'hidden',
      transition: {
        duration: 0.4,
        ease: 'easeInOut'
      }
    },
  };

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (window.innerWidth < 5000) {
      setShow(false);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  useEffect(() => {
    if (searchTerm.length > 1) {
      const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSuggestions(filteredProducts);
      setShowDropdown(true);
    } else {
      setSuggestions([]);
      setShowDropdown(false);
    }
  }, [searchTerm, products]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/search?query=${searchTerm}`);
      setSearchTerm('');
      setSuggestions([]);
      setShowDropdown(false);
      setSearchVisible(false);
      setShow(false);
    }
  };

  const handleSuggestionClick = (product) => {
    navigate(`/product/${product._id}`);
    setSearchTerm('');
    setSuggestions([]);
    setShowDropdown(false);
    setSearchVisible(false);
    setShow(false);
  };

  const handleSearchIconClick = () => {
    if (searchVisible) {
      setSearchVisible(false);
    } else {
      setSearchVisible(true);
    }
  };

  const handleSearchBlur = () => {
    setSearchVisible(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };


  const handleCategoryClick = (category) => {
    setActiveCategory(category === activeCategory ? null : category);
  };

  const handleNavigation = (url) => {
    navigate(url);
    handleLinkClick(); 
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY === 0) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
      if (currentScrollY > scrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
    }
    setScrollY(currentScrollY);
  }, [scrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  

  const { colorMode, toggleColorMode } = useColorMode();
  const headerBgColor = useColorModeValue(
    isAtTop
      ? 'transparent'
      : scrollDirection === 'up'
      ? 'rgba(255, 255, 255, 0.8)'  
      : 'transparent',
    isAtTop
      ? 'transparent'
      : scrollDirection === 'up'
      ? 'rgba(0, 0, 0, 0.8)'  
      : 'transparent'
  );
  

  return (
    <Flex
    as='header'
    align='center'
    justify='space-between'
    wrap='wrap'
    py='4'
    px='6'
    _hover={{
      bgColor: useColorModeValue('rgba(255, 255, 255, 0.97)', 'rgba(0, 0, 0, 0.9)'),
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    }}
    bgColor={headerBgColor}
    boxShadow={useColorModeValue(
      isAtTop
        ? 'none'
        : scrollDirection === 'up'
        ? '0 4px 30px rgba(0, 0, 0, 0.1)'
        : 'none',
      isAtTop ? 'none' : scrollDirection === 'up' ? '0 4px 30px rgba(255, 255, 255, 0.1)' : 'none'
    )}
    color={useColorModeValue('black', 'white')}
    w='100%'
    h={{base:'9%',md:'10%', lg:'17%'}}
    pos='fixed'
    top='0'
    left='0'
    zIndex='999'
    borderBottom='none'
  >
      {/* Menu Button */}
      <Box
      display='block'
      onClick={() => setShow(prev => !prev)}
      color={iconColor}  
      cursor='pointer'
      aria-label={show ? 'Close Menu' : 'Open Menu'}
      p='2'
      mt={{ base: '-5', md: '-5', lg: '-20' }}
    >
      {show ? <HiX size='24px' color={iconColor} /> : <HiOutlineMenuAlt3 size='24px' color={iconColor} />}
    </Box>

	  {/* Logo */}
      <Logo />

	  
       {/*  SocialLinks component  */}
       <Flex
          direction='row'
          align='center'
          ml={{ base: '0', md: '4', lg: '10' }} 
          mr={{ base: '70', md: '85', lg: '-150' }} 
		      mt={{ base: '-8', md: '-10', lg: '-10', xl:'-20', }} 
        >
          <SocialLinks />
        </Flex>

      {/* Search Bar */}
      <Flex
  align='center'
  justify='flex-end'
  flex='2'
  display={{ base: 'none', lg: 'flex', xl: 'flex', '2xl': 'flex' }}
>
<Flex
        as='form'
        onSubmit={handleSearch}
        align='center'
        border={`1px solid ${searchBorder}`} 
        borderRadius='none'
        bg={bgColor}  
        w='full'
        maxW='380px'
        py='1'
        pos='absolute'
        right='400'
        top='20%'
        display={{ base: 'none', lg: 'none', xl: 'flex', '2xl': 'flex' }}  
      >
        <Input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Search...'
          border='none'
          borderRadius='none'
          px='3'
          _focus={{ boxShadow: 'none' }}
          h='auto'
          fontWeight='normal'
          fontSize='md'
          letterSpacing='wide'
          color={useColorModeValue('black', 'white')}  
          _placeholder={{
            color: placeholderColor,  
            fontWeight: 'md',
            fontSize: 'sm',
            letterSpacing: '0em',
          }}
        />
        {showDropdown && (
          <Box
            pos='absolute'
            top='100%'
            left='0'
            right='0'
            bg={bgColor}  
            border={`1px solid ${borderColor}`}  
            borderTop='none'
            zIndex='999'
          >
           <VStack spacing='0' align='stretch'>
           {suggestions.length > 0 ? (
                  suggestions.map((product) => (
                <RouterLink
                  key={product._id}
                  to={`/product/${product._id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Box
                    p='2'
                    cursor='pointer'
                    _hover={{ bg: hoverBgColor }}
                    onClick={() => handleSuggestionClick(product)}
                    display='flex'
                    alignItems='center'
                  >
                    <Image
                      src={product.image1}
                      alt={product.name}
                      boxSize='50px'
                      objectFit='cover'
                      borderRadius='md'
                      mr='2'
                    />
                    <Text>{product.name}</Text>
                  </Box>
                </RouterLink>
              ))
            ) : (
              <Text p='4'>No products found</Text>
            )}
          </VStack>
        </Box>
      )}
  </Flex>
</Flex>


      {/* Desktop Menu */}
      {isLargeScreen && (
        <Flex align='center' justify='center' ml='auto' mt={-70} display={{ base: 'none', lg: 'flex' }}>
          <Flex direction='row' spacing='4' width='full' align='center' justify='space-between'>
          <Flex align='center' justify='center' ml='auto' display={{ base: 'none', lg: 'flex' }}>
          <IconButton
          aria-label='Toggle dark mode'
          onClick={toggleColorMode}
          icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          isRound
          bg='transparent'
          size='sm'
          mr='5'
        />
          <HeaderMenuItem url='/about' >About</HeaderMenuItem>
          <HeaderMenuItem url='/contact'>Contact</HeaderMenuItem>
          {!userInfo ? (
            <HeaderMenuItem url='/login'>Login</HeaderMenuItem>
          ) : (
            <>
              <UserMenu userInfo={userInfo} logoutHandler={logoutHandler} handleLinkClick={handleLinkClick} />
              {userInfo.isAdmin && <AdminMenu handleLinkClick={handleLinkClick} />}
			  <Flex align='center' justify='flex-end'>
          </Flex>
            </>
          )}
        </Flex>
          </Flex>
        </Flex>
      )}

	  

      {/* Mobile Menu */}
      <Box
        ref={menuRef}
        display={show ? 'flex' : 'none'}
        width='full'
        flexDirection='column'
        bg={bgColor}       
        p='2'
        alignItems='center'
        borderTop={`1px solid ${borderColor}`}  
        position='fixed'
        top='64px'
        left='0'
        zIndex='998'
		    mt={{ base:'-1', md:'4px', lg:'50'}}
      >
      <motion.div
        initial={false} 
        animate={show ? 'open' : 'closed'}
        variants={menuVariants}
        style={{ width: '100%', overflow: 'hidden' }} 
      >

        
        <Flex direction='column' width='full'  align='center'>
          {/* Category Links */}
          <Flex direction='row' spacing='4' width='full' align='center' justify='space-around'>
            <Text
              onClick={() => handleCategoryClick('chairs')}
              cursor='pointer'
              p='2'
              _hover={{ textDecoration: 'underline' }}
              fontWeight={activeCategory === 'chairs' ? 'bold' : 'normal'}
            >
              Chairs
            </Text>
            <Text
              onClick={() => handleCategoryClick('lounge')}
              cursor='pointer'
              p='2'
              
            _hover={{ textDecoration: 'underline' }}
            fontWeight={activeCategory === 'lounge' ? 'bold' : 'normal'}
            >
              Lounge
            </Text>
            {/* Mobile Search Bar (only visible on smaller screens) */}
         {!isLargeScreen && (
          <Flex align='center' position='relative' >
            <IconButton
                  icon={searchVisible ? <HiX /> : <HiSearch />} 
                  aria-label={searchVisible ? 'Close Search' : 'Search'}
                  onClick={handleSearchIconClick}
                  variant='ghost'
                  color='iconColor'
                  mr='2'
                 _hover={{ transform: 'scale(1.2)', transition: 'transform 0.2s' }} 

                />
            {searchVisible && (
              <Flex
                as='form'
                onSubmit={handleSearch}
                direction='column'
                position='absolute'
                top='130%' 
                left={{base:'-245',md:'-390'}}
                width={{base:'270px',md:'320px'}}  
                bg={bgColor} 
                border='1px  black'
                borderRadius='none'
                boxShadow='md'
                zIndex='1000'
              >
                <Input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onBlur={handleSearchBlur}
                  onKeyDown={handleKeyDown}
                  placeholder='Search...'
                  border={`1px solid ${searchBorder}`} 
                  borderRadius='none'
                  bg={bgColor}  
                  px='3'
                  _hover={{ borderColor:'black'}}
                  _focus={{ boxShadow: 'none', borderColor: 'black' }}  
                  h='auto'
                  fontWeight='normal'
                  fontSize='md'
                  letterSpacing='wide'
                  _placeholder={{
                    color: placeholderColor, 
                    fontWeight: 'md',
                    fontSize: 'sm',
                    letterSpacing: '0em',
                  }}
                />
                {showDropdown && (
                  <Box
                    pos='absolute'
                    top='100%'
                    left='0'
                    w='full'
                    bg={bgColor} 
                    border={`1px solid ${borderColor}`}
                    borderRadius='md'
                    mt='2'
                    boxShadow='md'
                    zIndex='1000'
                  >
                    <VStack spacing='2' p='2'>
                      {suggestions.length > 0 ? (
                        suggestions.map((product) => (
                          <Box
                          p='2'
                          cursor='pointer'
                          _hover={{ bg: hoverBgColor }}
                          onClick={() => handleSuggestionClick(product)}
                          display='flex'
                          alignItems='center'
                        >
                          <Image
                            src={product.image1}
                            alt={product.name}
                            boxSize='50px'
                            objectFit='cover'
                            borderRadius='md'
                            mr='55'
                          />
                          <Text mr='10'>{product.name}</Text>
                          </Box>
                        ))
                      ) : (
                        <Text p='2'>No results found</Text>
                      )}
                    </VStack>
                  </Box>
                )}
              </Flex>
            )}
          </Flex>
        )}
          </Flex>

          {/* Line */}
          <Box width='full' borderBottom='1px solid #eaeaea' my='2' />

          {/* Subcategories */}
          {activeCategory === 'chairs' && (
            <VStack spacing='4' align='center' width='full'>
              <Text onClick={() => handleNavigation('/products/Executive')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>EXECUTIVE CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/ErgonomicOffice')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>ERGONOMIC OFFICE CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/Workstation')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>WORK STATION CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/Gaming')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>GAMING CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/Staff')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>STAFF CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/Visitors')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>VISITORS CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/TandemSeating')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>TANDEM SEATING</Text>
              <Text onClick={() => handleNavigation('/products/Training')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>TRAINING CHAIRS</Text>
              <Text onClick={() => handleNavigation('/products/CafeteriaOutdoor')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>CAFETERIA & OUTDOOR CHAIRS</Text>
              <Text onClick={() => handleNavigation('/HighCounter')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>HIGH COUNTER SERIES</Text>

            </VStack>
          )}
          {activeCategory === 'lounge' && (
            <VStack spacing='4' align='center' width='full'>
             <Text onClick={() => handleNavigation('/products/LoungeChairs')} cursor='pointer' _hover={{ textDecoration: 'underline' }}>LOUNGE CHAIRS</Text>

            </VStack>
          )}

          {/* Links */}
          {!isLargeScreen && (
  <Flex direction='row' width='full' justify='space-between' mt='4' px='4'>
    <Flex direction='row' align='center' gap='4'>
      <Text onClick={() => handleNavigation('/about')} cursor='pointer' p='2' _hover={{ textDecoration: 'underline' }}>About</Text>
      <Text onClick={() => handleNavigation('/contact')} cursor='pointer' p='2' _hover={{ textDecoration: 'underline' }}>Contact</Text>
      <IconButton
            aria-label='Toggle dark mode'
            onClick={() => {
              toggleColorMode();
              handleNavigation(window.location.pathname);
            }}
            icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
            isRound
            bg='transparent'
            size='sm'
            ml='3'
          />
    </Flex>
    <Flex direction='row' align='center' gap='6'>
      {!userInfo ? (
        <Text onClick={() => handleNavigation('/login')} cursor='pointer' p='2' _hover={{ textDecoration: 'underline' }}>Login</Text>
      ) : (
        <>
          {userInfo.isAdmin && <AdminMenu handleLinkClick={handleLinkClick} />}
          <UserMenu userInfo={userInfo} logoutHandler={logoutHandler} handleLinkClick={handleLinkClick} />
        </>
      )}
    </Flex>
  </Flex>
)}

        </Flex>
        </motion.div>
      </Box>   
    </Flex>
  );
};

export default Header;
