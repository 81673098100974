import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const SocialLinks = () => {
  const iconColor = useColorModeValue('black', 'white');

  const handleRedirect = (e, appUrl, webUrl) => {
    e.preventDefault(); 

    const isSmallScreen = window.innerWidth <= 600; 
    if (isSmallScreen) {
      window.location.href = appUrl;
    } else {
      window.open(webUrl, '_blank');
    }
  };

  return (
    <Flex direction='row' gap='12px' position='absolute' align='center'>
       <Box
        as='a'
        href='https://www.facebook.com/RegentNXChairs'
        target='_blank'
        rel='noopener noreferrer'
        _hover={{ transform: 'scale(1.2)', transition: 'transform 0.2s' }} 
      >
        <FaFacebookF size='20px' color={iconColor} />
      </Box>

      <Box
        as='a'
        href='https://www.instagram.com/regentnxchairs?igsh=bnpqMjAwdWRzbTlk'
        target='_blank'
        rel='noopener noreferrer'
        onClick={(e) => handleRedirect(e, 'instagram://user?username=regentnxchairs', 'https://www.instagram.com/regentnxchairs?igsh=bnpqMjAwdWRzbTlk')}
        _hover={{ transform: 'scale(1.2)', transition: 'transform 0.2s' }} 
      >
        <FaInstagram size='20px' color={iconColor} />
      </Box>

      <Box
        as='a'
        href='https://wa.me/919833602186?text=Hello%2C%20I%20am%20interested%20in%20your%20products.'
        target='_blank'
        rel='noopener noreferrer'
        onClick={(e) => handleRedirect(e, 'whatsapp://send?phone=919833602186&text=Hello%2C%20I%20am%20interested%20in%20your%20products.', 'https://wa.me/919833602186?text=Hello%2C%20I%20am%20interested%20in%20your%20products.')}
        _hover={{ transform: 'scale(1.2)', transition: 'transform 0.2s' }} 
      >
        <FaWhatsapp size='20px' color={iconColor} />
      </Box>
    </Flex>
  );
};

export default SocialLinks;
