import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useBreakpointValue,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { IoAdd, IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
	createProduct,
	deleteProduct,
	listProducts,
} from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

const ProductListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, error, products } = useSelector((state) => state.productList);
	const { loading: loadingDelete, error: errorDelete, success: successDelete } = useSelector((state) => state.productDelete);
	const { loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct } = useSelector((state) => state.productCreate);
	const { userInfo } = useSelector((state) => state.userLogin);

	useEffect(() => {
		if (!userInfo.isAdmin) {
			navigate('/login');
			return;
		}

		if (successCreate) {
			navigate(`/admin/product/${createdProduct._id}/edit`);
		} else {
			dispatch(listProducts());
		}

		dispatch({ type: PRODUCT_CREATE_RESET });
	}, [dispatch, navigate, userInfo, successCreate, createdProduct, successDelete]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure')) {
			dispatch(deleteProduct(id));
		}
	};

	const createProductHandler = () => {
		const productData = {
			name: 'New Product',
			price: 0,
			images: [],
			type: 'Default Type',
			category: 'Default Category',
			description: 'Default Description',
			features: [],
			addInfo: [],
			countInStock: 0,
		};
		dispatch(createProduct(productData));
	};

	useEffect(() => {
		document.title = 'Product List | REGENT NX CHAIRS';
	  }, []);

	const headingSize = useBreakpointValue({ base: 'xl', md: '2xl',lg:'3xl' });
	const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
	const boxPadding = useBreakpointValue({ base: '4', md: '6' });
	const marginTop = useBreakpointValue({ base: '100', md: '150' });
	const tableSize = useBreakpointValue({ base: 'sm', md: 'lg' });

	const bgColor = useColorModeValue('white', 'gray.800');
	const textColor = useColorModeValue('black', 'whiteAlpha.800');
	const buttonBgColor = useColorModeValue('black', 'gray.700');
	const buttonHoverBgColor = useColorModeValue('gray.800', 'gray.600');

	return (
		<>
			<Flex
				mt={marginTop}
				mb={{ base: '4', md: '6' }}
				direction={{ base: 'column', md: 'row' }}
				alignItems='center'
				justifyContent='space-between'
			>
				<Heading as='h1' fontWeight='md' fontSize={headingSize} mb={{ base: '4', md: '0' }} ml={{ base: '4', md: '150', lg:'45%' }} color={textColor}>
					Product List
				</Heading>
				<Button
					onClick={createProductHandler}
					fontSize={{ base: 'sm', md: 'sm', lg: 'sm' }}
					letterSpacing="wide"
					borderRadius="none"
					width="200px"
					bg={buttonBgColor}
					color="white"
					_hover={{ bg: buttonHoverBgColor }}
					_active={{
						bg: buttonBgColor,
						color: 'white',
						transform: 'scale(0.95)',
					}}
					size={buttonSize}
				>
					<Icon as={IoAdd} mr='2' fontSize='lg' /> Create Product
				</Button>
			</Flex>

			{loadingDelete && <Loader />}
			{errorDelete && <Message type='error'>{errorDelete}</Message>}
			{loadingCreate && <Loader />}
			{errorCreate && <Message type='error'>{errorCreate}</Message>}

			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Box
					bgColor={bgColor}
					rounded='lg'
					shadow='lg'
					p={boxPadding}
					overflowX='auto'
					mx={{ base: '2', md: 'auto' }} 
					overflowY='hidden' 
				>
					<Table colorScheme='none' size={tableSize}>
						<Thead>
							<Tr>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>ID</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>Name</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>Price</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>Category</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>Type</Th>
								<Th color={textColor} fontSize={{ base: 'sm', md: 'md' }}>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{products.map((product) => (
								<Tr key={product._id}>
									<Td fontSize={{ base: 'sm', md: 'md' }} bgColor={bgColor}>{product._id}</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }} bgColor={bgColor}>{product.name}</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }} bgColor={bgColor}>{product.price}</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }} bgColor={bgColor}>{product.category}</Td>
									<Td fontSize={{ base: 'sm', md: 'md' }} bgColor={bgColor}>{product.type}</Td>
									<Td bgColor={bgColor}>
										<Flex
											direction={{ base: 'column', md: 'row' }}
											justifyContent='flex-end'
											gap='2'
										>
											<Button
												mr={{ base: '0', md: '2' }}
												as={RouterLink}
												to={`/admin/product/${product._id}/edit`}
												colorScheme='teal'
												size={buttonSize}
												aria-label={`Edit ${product.name}`}
											>
												<Icon as={IoPencilSharp} color='white' />
											</Button>
											<Button
												colorScheme='red'
												size={buttonSize}
												onClick={() => deleteHandler(product._id)}
												aria-label={`Delete ${product.name}`}
											>
												<Icon as={IoTrashBinSharp} color='white' />
											</Button>
										</Flex>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			)}
		</>
	);
};

export default ProductListScreen;
