import React, { useEffect } from 'react';
import { Box, Heading, Text, Stack, Button, Flex, Divider, useColorModeValue } from '@chakra-ui/react';

const Contact = () => {  

  const bg = useColorModeValue('white', 'black');
  const headingColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const textColor = useColorModeValue('gray.700', 'whiteAlpha.800');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const dividerColor = useColorModeValue('gray.300', 'gray.600');
  const buttonBg = useColorModeValue('black', 'gray.800');
  const buttonTextColor = useColorModeValue('white', 'white');

  useEffect(() => {
    document.title = 'Contact | REGENT NX CHAIRS';
  }, []);

  return (
    <Box p={6} maxW="1000px" mx="auto" mt={{ base: '60px', sm: '80px' }} textAlign="center">
      <Heading mb={10} fontSize={{ base: '2xl', md: '4xl' }} color={headingColor} fontWeight="light">
        Contact Us
      </Heading>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align="flex-start"
        gap={8} 
      >
        {/* Contact Info Section */}
        <Box
          width={{ base: '100%', lg: '90%' }}
          p={8}
          borderWidth={1}
          borderColor={borderColor}
          boxShadow="sm"
          backgroundColor={bg}
        >
          <Heading size="lg" mb={6} fontWeight="light" color={headingColor}>
            Reach Us
          </Heading>
          <Stack spacing={4} textAlign="left">
            <Text fontWeight="md" fontSize="lg" color={textColor}>Contact Person: Mr. Shakir Hussain</Text>
            <Text color={textColor}>Mobile: +91 98204 07459</Text>

            <Text fontWeight="md" fontSize="lg" color={textColor}>Contact Person: Mr. Uzair Hussain</Text>
            <Text color={textColor}>Mobile: +91 90049 16011</Text>

            <Text fontWeight="md" fontSize="lg" color={textColor}>Contact Person: Mr. Zaid Hussain</Text>
            <Text color={textColor}>Mobile: +91 98336 02186</Text>

            <Divider my={4} borderColor={dividerColor} />
            
            <Text fontSize="sm" color={textColor}>
              250/A Wadia Building, Opp. Parsi Dairy Farm,
            </Text>
            <Text fontSize="sm" color={textColor}>
              S.G.Marg, Princess Street,
            </Text>
            <Text fontSize="sm" color={textColor}>
              Mumbai 400 002. India.
            </Text>
            <Divider my={4} borderColor={dividerColor} />
            <Text fontSize="sm" color={textColor}>
              Phone: +91 72082 36383 | +91-22-2207 4003
            </Text>
            <Text fontSize="sm" color={textColor}>
              Email: regentnxchairs@gmail.com
            </Text>
          </Stack>
        </Box>

        {/* Call Section */}
        <Box
          width={{ base: '100%', lg: '60%' }}
          mt={{ base: 10, lg: 0 }}
          p={8}
          borderWidth={1}
          borderColor={borderColor}
          boxShadow="sm"
          backgroundColor={bg}
        >
          <Heading size="lg" mb={6} fontWeight="light" color={headingColor}>
            Have a Query? Call Us Now!
          </Heading>
          <Stack spacing={5}>
            <Button
              size="lg"
              width="100%"
              bg={buttonBg}
              color={buttonTextColor}
              _hover={{ bg: buttonBg, color: buttonTextColor }}
              _focus={{ bg: buttonBg, color: buttonTextColor }}
              _active={{
                bg: buttonBg,
                color: buttonTextColor,
                transform: 'scale(0.95)',
              }}
              transition="transform 0.1s ease"
              borderRadius='none'
              onClick={() => window.location.href = 'tel:+919833602186'}
            >
              Call
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Contact;
