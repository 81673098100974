import {
	Button,
	Flex,
	FormControl,
	Input,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile } from '../actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';

const ProfileScreen = () => {
	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState(null);

	const [errors, setErrors] = useState({});

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
	const { success } = userUpdateProfile;

	const bgColor = useColorModeValue('white', 'black');
	const inputBorderColor = useColorModeValue('gray', 'gray.100');
	const inputFocusBorderColor = useColorModeValue('black', 'gray.100');
	const textColor = useColorModeValue('gray.800', 'white');
	const placeholderColor = useColorModeValue('gray.500', 'gray.100');

	useEffect(() => {
		document.title = 'Profile | REGENT NX CHAIRS';
	  }, []);
	

	useEffect(() => {
		if (!user || !user.name) {
			dispatch({ type: USER_UPDATE_PROFILE_RESET });
		} else {
			setName(user.name);
			setEmail(user.email);
		}
	}, [dispatch, user]);

	const validateFields = () => {
		const newErrors = {};
		if (!name) newErrors.name = 'Name is required';
		if (!email) newErrors.email = 'Email is required';
		if (password && confirmPassword && password !== confirmPassword)
			newErrors.confirmPassword = 'Passwords do not match';
		return newErrors;
	};

	const submitHandler = (e) => {
		e.preventDefault();
		const fieldErrors = validateFields();
		if (Object.keys(fieldErrors).length > 0) {
			setErrors(fieldErrors);
		} else {
			setErrors({});
			dispatch(
				updateUserProfile({
					id: user._id,
					name,
					email,
					password,
				})
			)
			.then(() => {
				setMessage("Profile updated successfully!");
			})
			.catch(err => {
				setMessage("Failed to update profile. Please try again.");
			});
		}
	};

	const getBorderColor = (field) => (errors[field] ? 'red.500' : 'gray');

	return (
		<Flex w="full" h="100vh" p={4} bg={bgColor} align="center" justify="center">
			<Flex
				direction="column"
				maxW="1000px"
				mx="auto"
				w="full"
				h="full"
				p={8}
				bg={bgColor}
			>
				<Flex
					direction="column"
					ml={{ base: '-14', md: '0', lg: '-65' }}
					align="flex-start"
					mt={{ base: '20', md: '82', lg:'85' }}
					justify="center"
					w={{ base: '90%', md: '50%' }}
					p={6}
				>
					<Heading as="h1" mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'lg' }} fontWeight="normal" color={textColor}>
						USER PROFILE
					</Heading>

					{error && (
						<Text fontSize="sm" color="red.500" mb={4}>
							{error}
						</Text>
					)}
					{message && (
						<Text fontSize="sm" color="red.500" mb={4}>
							{message}
						</Text>
					)}
					{success && (
						<Text fontSize="sm" color="green.500" mb={4}>
							Profile Updated Successfully
						</Text>
					)}

					<form onSubmit={submitHandler}>
						<FormControl id="name" width="400px" mb={0}>
							<Input
								id="name"
								type="text"
								placeholder="NAME"
								fontSize="sm"
								value={name}
								onChange={(e) => setName(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('name')}
								_placeholder={{ color: errors.name ? 'red.500' :  placeholderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.name && (
								<Text fontSize="sm" color="red.500">
									{errors.name}
								</Text>
							)}
						</FormControl>

						<FormControl id="email" width="400px" mb={0}>
							<Input
								id="email"
								type="email"
								placeholder="E-MAIL"
								fontSize="sm"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('email')}
								_placeholder={{ color: errors.email ? 'red.500' : placeholderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.email && (
								<Text fontSize="sm" color="red.500">
									{errors.email}
								</Text>
							)}
						</FormControl>

						<FormControl id="password" width="400px" mb={0}>
							<Input
								id="password"
								type="password"
								placeholder="NEW PASSWORD (Optional)"
								fontSize="sm"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('password')}
								_placeholder={{ color: errors.password ? 'red.500' : inputBorderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor}}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.password && (
								<Text fontSize="sm" color="red.500">
									{errors.password}
								</Text>
							)}
						</FormControl>

						<FormControl id="confirmPassword" width="400px" mb={0}>
							<Input
								id="confirmPassword"
								type="password"
								placeholder="CONFIRM PASSWORD"
								fontSize="sm"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								size="lg"
								border="none"
								letterSpacing="wide"
								borderBottom="1px solid"
								borderBottomColor={getBorderColor('confirmPassword')}
								_placeholder={{ color: errors.confirmPassword ? 'red.500' : inputBorderColor }}
								borderRadius="none"
								_focus={{
									borderBottomColor: inputFocusBorderColor,
									outline: 'none',
									boxShadow: 'none',
								}}
								_hover={{ borderBottomColor: inputFocusBorderColor }}
								paddingBottom="0px"
								bg="transparent"
							/>
							{errors.confirmPassword && (
								<Text fontSize="sm" color="red.500">
									{errors.confirmPassword}
								</Text>
							)}
						</FormControl>

						<Button
							type="submit"
							size="md"
							isLoading={loading}
							borderRadius="none"
							width="full"
							mb={-32}
							bg={useColorModeValue('black', 'gray.100')}
							color={useColorModeValue('white', 'black')}
							letterSpacing="wide"
							fontSize={{ base: 'md', md: 'md', lg: 'md' }}
							_hover={{ bg: useColorModeValue('gray.800', 'gray.600') }}
							_active={{
								bg: useColorModeValue('black', 'gray.700'),
								color: 'white',
								transform: 'scale(0.95)',
							}}
						>
							UPDATE
						</Button>
					</form>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ProfileScreen;
