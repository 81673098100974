import { Box, Flex, Icon, Text, useColorMode } from '@chakra-ui/react';
import { IoStar, IoStarHalf, IoStarOutline } from 'react-icons/io5';

const Rating = ({ value, text, color }) => {
  const { colorMode } = useColorMode(); // Get the current color mode
  
  // Determine the star color based on the color mode and passed color prop
  const starColor = color || (colorMode === 'dark' ? 'white' : 'black');

  return (
    <Flex align="center">
      <Box mr="2">
        <Icon
          as={value >= 1 ? IoStar : value >= 0.5 ? IoStarHalf : IoStarOutline}
          color={starColor}
        />
        <Icon
          as={value >= 2 ? IoStar : value >= 1.5 ? IoStarHalf : IoStarOutline}
          color={starColor}
        />
        <Icon
          as={value >= 3 ? IoStar : value >= 2.5 ? IoStarHalf : IoStarOutline}
          color={starColor}
        />
        <Icon
          as={value >= 4 ? IoStar : value >= 3.5 ? IoStarHalf : IoStarOutline}
          color={starColor}
        />
        <Icon
          as={value >= 5 ? IoStar : value >= 4.5 ? IoStarHalf : IoStarOutline}
          color={starColor}
        />
      </Box>
      <Text color={colorMode === 'dark' ? 'white' : 'black'}>{text}</Text> {/* Adjust text color based on color mode */}
    </Flex>
  );
};

export default Rating;
