import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useColorModeValue } from '@chakra-ui/react';

const HeaderMenuItem = ({ url, children }) => {
  // Use useColorModeValue to set color based on the current color mode
  const color = useColorModeValue('black', 'white');

  return (
    <Link
      as={RouterLink}
      to={url}
      fontSize='sm'
      fontWeight='md'
      letterSpacing='wide'
      textTransform='uppercase'
      mr='5'
      gap='1'
      display='flex'
      alignItems='center'
      color={color} // Apply dynamic color based on the color mode
      mt={{ base: '2', md: '0' }}
    >
      {children}
    </Link>
  );
};

export default HeaderMenuItem;
