import { Link, Image, Text, useBreakpointValue, Grid, GridItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import image2 from '../Assets/images/ws1.jpg';
import image3 from '../Assets/images/Executive.jpg';

const Banner2 = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + windowHeight > documentHeight - 200); 

    setScrollY(scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const imageSize = useBreakpointValue({ base: '50vh', md:'70vh', lg: '100vh' });

  const navigate = useNavigate();

  return (
    <Grid
      templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }} 
      gap={0}
      width="100%"
      height={{ base: '40vh',md:'50vh', lg: '100vh' }} 
    >
      <GridItem
        colSpan={{ base: 1, md: 1 }} 
        height={imageSize}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        position="relative"
        transition="transform 0.5s ease, box-shadow 0.5s ease"
        transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 + (scrollY / 1000) * -0.2 : 1})`}
        _hover={{
          transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 + (scrollY / 1000) * -0.2 : 1.05})`,
          boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)',
        }}
      >
        <Link onClick={() => navigate('/products/Executive')} width="100%" height="100%">
          <Image
            src={image3}
            alt="Banner 2"
            objectFit={{base:'auto', md:'fill', lg:'cover'}} 
            width="100%"
            mt={{base:'-68', md:'-230',lg:'0'}}
            height="100%"
            filter="brightness(100%)"
          />
          <Text
             fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
             fontWeight="semibold"
            color="white"
            letterSpacing='wider'
            position="absolute"
            bottom={{base:'25%', md:'53%',lg:'10%'}}
            left="5%"
            p="2"
            background="rgba(0, 0, 0, 0.4)"
            borderRadius="md"
            maxWidth="80%"
            zIndex="1"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
            textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
           transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
           _hover={{
             transform: 'translateY(-15px)',
             textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
           }}
          >
            EXECUTIVE CHAIRS
          </Text>
        </Link>
      </GridItem>
      <GridItem
        colSpan={{ base: 1, md: 1 }} 
        height={imageSize}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        position="relative"
        transition="transform 0.5s ease, box-shadow 0.5s ease"
        transform={`translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 + (scrollY / 1000) * -0.2 : 1})`}
        _hover={{
          transform: `translateY(${isNearBottom ? (scrollY - (window.innerHeight * 0.5)) * -0.5 : 0}px) scale(${isNearBottom ? 1 + (scrollY / 1000) * -0.2 : 1.05})`,
          boxShadow: '0 15px 25px rgba(0, 0, 0, 0.4)',
        }}
      >
        <Link onClick={() => navigate('/products/Workstation')} width="100%" height="100%">
          <Image
            src={image2}
            alt="Banner 3"
            objectFit={{base:'auto', md:'fill', lg:'cover'}} 
            width="100%"
            mt={{base:'-62', md:'-230',lg:'0'}}
            height="100%"
            filter="brightness(100%)"
          />
          <Text
             fontSize={{ base: '12px', md: 'sm', lg:'md'}} 
             fontWeight="semibold"
             color="white"
             letterSpacing='wider'
             position="absolute"
             bottom={{base:'25%', md:'53%',lg:'10%'}}
             left="5%"
             p="2"
             background="rgba(0, 0, 0, 0.4)"
             borderRadius="md"
             maxWidth="80%"
             zIndex="1"
             boxShadow="0 4px 8px rgba(0, 0, 0, 0.4)"
             textShadow="1px 1px 3px rgba(0, 0, 0, 0.6)"
            transition="transform 0.4s ease, box-shadow 0.4s ease, text-shadow 0.4s ease"
            _hover={{
              transform: 'translateY(-15px)',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
            }}
          >
            WORK STATION CHAIRS
          </Text>
        </Link>
      </GridItem>
    </Grid>
  );
};

export default Banner2;
